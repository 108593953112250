import { Component, Input, OnInit } from '@angular/core';
import { YshTableSetComponent } from '../../components/ysh-table-set/ysh-table-set.component';
import { YshOrderStatusCellComponent } from '../schedule/components/ysh-order-status-cell/ysh-order-status-cell.component';
import { Order } from '../../models/order';
import { OrderService } from '../../services/order/order.services';
import { MatListModule } from '@angular/material/list';
import { YshPageHeaderComponent } from '../../components/ysh-page-header/ysh-page-header.component';
import { YshAttributesComponent, AttributesListItem } from "../../components/ysh-attributes/ysh-attributes.component";
import { YshGalleryComponent, GalleryImage } from '../../components/ysh-gallery/ysh-gallery.component';
import { AppRoutes } from '../../app.routes';

@Component({
  selector: 'app-order',
  templateUrl: './order.component.html',
  styleUrl: './order.component.scss',
  standalone: true,
  imports: [
    MatListModule,
    YshTableSetComponent,
    YshOrderStatusCellComponent,
    YshPageHeaderComponent,
    YshAttributesComponent,
    YshGalleryComponent,
  ],
})
export class OrderComponent implements OnInit {

  @Input() uid: string;

  order: Order;
  orderAttributes: AttributesListItem[];
  orderPhotos: GalleryImage[] = [];
  orderVehicleRoute: string;

  constructor(private orderService: OrderService) {}

  ngOnInit(): void {
    this.getOrder();
  }

  getOrder() {
    this.orderService.get(this.uid).subscribe(order => {
      this.order = order;
      this.orderVehicleRoute = `/${AppRoutes.Vehicles}/${this.order.vehicle.uid}`;
      this.orderPhotos = this.order.photos.map(photo => ({
        src: photo.url,
        caption: photo.takenAtFormatted,
      }));
      this.orderAttributes = [
        { label: 'Year/Make/Model', value: this.order?.vehicle?.description },
        { label: 'Service', value: this.order?.service?.name},
        { label: 'License', value: this.order?.vehicle.license },
        { label: 'VIN', value: this.order?.vehicle.vin },
        { label: 'Time Completed', value: this.order?.timeCompletedString }
      ];

      if (this.order.serviceType.name === 'gas_refill'){
        this.orderAttributes.push({
          label: 'Gallons',
          value: this.order.numGallonsPumped
        })
      }
    });
  }
}
