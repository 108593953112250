import { Component, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { delay, first, firstValueFrom, map, Observable } from 'rxjs';
import { UserService } from '../../services/user/user.service';
import { FormField, FormLink, YshFormComponent } from '../../components/ysh-form/ysh-form.component';
import { YshSplitLayoutComponent } from "../../components/ysh-split-layout/ysh-split-layout.component";
import { Router } from '@angular/router';
import { AppRoutes } from '../../app.routes';
import { AnalyticsService } from '../../services/analytics/analytics.service';
import { MapGeocoder } from '@angular/google-maps';
import generator from 'generate-password-ts'
import { AddressParams, addressParamsForGooglePlaceData } from '../../models/user';


@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrl: './signup.component.scss',
  standalone: true,
  imports: [YshFormComponent, YshSplitLayoutComponent],
})
export class SignupComponent implements OnInit {

  formFields: FormField[] = [
    { 
      name: 'firstName',
      label: 'First Name',
      type: 'text',
      half: true,
      validators: [Validators.required] 
    },
    { 
      name: 'lastName',
      label: 'Last Name',
      type: 'text',
      half: true,
      validators: [Validators.required] 
    },
    { 
      name: 'phone',
      label: 'Phone Number',
      type: 'tel',
      icon: 'phone',
      validators: [Validators.required]
    },
    {
      name: 'email',
      label: 'Email Address',
      type: 'email',
      icon: 'alternate_email',
      validators: [Validators.required, Validators.email]
    },
    {
      name: 'zipCode',
      label: 'Zipcode',
      type: 'number',
      validators: [Validators.required, Validators.pattern('^[0-9]{5}$')]
    }
  ];

  formLinks: FormLink[] = [
    {
      href: AppRoutes.Login,
      label: 'Have an Account? Sign in.',
    },
  ]

  constructor(
    private analytics: AnalyticsService,
    private geocoder: MapGeocoder,
    private router: Router, 
    private userService: UserService, 

    ) {}

  ngOnInit(): void {
    this.analytics.trackView('SignupComponent');
  }

  async handleSignup(formValues: any): Promise<void> {  
    const password = this.generatePassword();
    try {
      const adddressParams = await firstValueFrom(this.geocodeZipCode(formValues.zipCode));
      if (!adddressParams.zipCode) {
        throw "Please use a valid zip code."
      }
      await firstValueFrom(
        this.userService.signup({
          firstName: formValues.firstName,
          lastName: formValues.lastName,
          phone: formValues.phone,
          email: formValues.email,
          password: password,
          ...adddressParams
        }).pipe(delay(500))
      );
      await firstValueFrom(
        this.userService.login(formValues.email, password)
      );
      this.analytics.trackSignup();
      this.router.navigate([AppRoutes.OrderFlow]);
    } catch (error) {
      console.error(error);
      throw error; // Throw error for YshFormComponent to handle
    }
  }

  geocodeZipCode(zipCode: string): Observable<AddressParams> {
    return this.geocoder.geocode({ address: String(zipCode) }).pipe(
      map((response) => {
        if (response.status !== google.maps.GeocoderStatus.OK) {
          throw new Error('Invalid Zip: ' + response.status);
        }
        if (!response.status[0]) {
          throw new Error('Invalid Zip: Not found');
        }
        const params = addressParamsForGooglePlaceData(response.results[0] as any);
        return params;
      })
    );
  }

  private generatePassword(){
    return generator.generate({
      length: 16,
      numbers: true,
      symbols: '%',
      uppercase: true,
      lowercase: true,
      strict: true
    });
  }
}
