import { Component, OnInit } from '@angular/core';
import { User } from '../../../models/user';
import { YshPageHeaderComponent } from "../../../components/ysh-page-header/ysh-page-header.component";
import { YshPanelComponent } from '../../../components/ysh-panel/ysh-panel.component';
import { Trait, YshTraitsComponent } from '../../../components/ysh-traits/ysh-traits.component';
import { MatIconModule } from '@angular/material/icon';
import { ActivatedRoute } from '@angular/router';
import { AppRoutes } from '../../../app.routes';
import { AffiliationService } from '../../../services/api/affiliation/affiliation.service';
import { CompanyService } from '../../../services/api/company/company.service';
import { first } from 'rxjs';

@Component({
  selector: 'app-company-member',
  templateUrl: './company-member.component.html',
  styleUrl: './company-member.component.scss',
  standalone: true,
  imports: [YshPageHeaderComponent, YshPanelComponent, YshTraitsComponent, MatIconModule],
})
export class CompanyMemberComponent implements OnInit {
  member: User;
  memberInfo?: Trait[];
  companyUid!: string;
  backLinkRoute: string;

  constructor(
    private affiliationService: AffiliationService,
    private route: ActivatedRoute,
    private companyService: CompanyService,
  ) {}
  
  ngOnInit() {
    this.getCompany();
    this.getMemberInfo();
  }

  getCompany() {
    this.companyService.currentCompany$.pipe(first()).subscribe(company => {
      this.backLinkRoute = `/${AppRoutes.Companies}/${company.uid}`;
    });
  }

  getMemberInfo() {
    const memberUid = this.route.snapshot.paramMap.get('memberUid');
    memberUid && this.affiliationService.get(memberUid).subscribe(affiliation => {
      this.memberInfo = [
        { label: 'Name', value: affiliation?.userName || 'None' },
        { label: 'Phone Number', value: affiliation?.userPhoneFormatted || 'None' },
        { label: 'Email Address', value: affiliation?.userEmail || 'None'},
        { label: 'Account Role', value: affiliation?.roleFormatted || 'None'},
      ];
    });
  }
}
