import moment from 'moment';
import { BaseEntity, MemoizeGetters, toDate, Transform } from './base';

@MemoizeGetters
export class Company extends BaseEntity{
  name: string; 

  accountUid: string;

  @Transform(toDate)
  createdAt: Date; 

  hasValidPaymentMethod: boolean;

  get createdAtFormatted(){
    return moment(this.createdAt).format('LL');
  }
}
