import { BaseEntity, Default, MemoizeGetters, Transform, toDate  } from "./base";
import moment from "moment";


@MemoizeGetters
export class User extends BaseEntity {
  firstName?: string;
  lastName?: string;
  email?: string;
  phone?: string;

  @Transform(toDate)
  createdAt?: Date;

  @Default([])
  regionUids: string[];

  get name(): string {
    return `${this.firstName || ''} ${this.lastName || ''}`.trim();
  }

  get createdAtFormatted() {
    return moment(this.createdAt).format('LL');
  }

  get primaryRegionUid(): string {
    return this.hasRegions && this.regionUids[0];
  }

  get hasRegions(): boolean {
    return Boolean(this.regionUids.length);
  }
}
