import { Component, Input } from '@angular/core';

export type YshBadgeTheme = 'mint' | 'coral' | 'canary' | 'smoke' | 'status';

export interface YshBadgeProps {
  theme?: YshBadgeTheme;
  value?: string;
}

@Component({
  selector: 'ysh-badge',
  templateUrl: './ysh-badge.component.html',
  styleUrl: './ysh-badge.component.scss',
  standalone: true,
  imports: [],
})
export class YshBadgeComponent extends Component {
  @Input() theme: YshBadgeTheme;
  @Input() value: YshBadgeProps['value'];

  get badgeTheme(): YshBadgeTheme {
    const status: Record<string, YshBadgeTheme> = {
      complete: 'mint',
      pending: 'canary',
      ok: 'mint',
      pass: 'mint',
      fail: 'coral',
      'needs repair': 'coral',
    };
  
    if (this.theme && this.theme !== 'status') {
      return this.theme;
    }

    return status[this.value?.toLowerCase()] || 'smoke';
  } 
}
