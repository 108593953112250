import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { CompanyService } from '../api/company/company.service';
import { Action, PermissionService, Resource } from '../permission/permission.service';
import { DialogService } from '../ui/dialog/dialog.service';
import { firstValueFrom } from 'rxjs';

@Injectable({providedIn: 'root'})

export class OrderFlowGuard implements CanActivate {

  constructor(
    private companyService: CompanyService,
    private permissionService: PermissionService,
    private dialogService: DialogService,
  ) {}

  async canActivate(): Promise<boolean> {
    const company = await firstValueFrom(this.companyService.currentCompany$);
    const permissions = await firstValueFrom(this.permissionService.getPermissions(company));
    const canAccessPaymentMethod = permissions?.canAccess(Action.GetMany, Resource.CreditCard);
  
    if (!canAccessPaymentMethod && !company?.hasValidPaymentMethod) {
      this.dialogService.showDialog({
        heading: 'No Payment Method!',
        caption: 'Your organization must have a payment method saved before you can schedule appointments.',
        buttonLabelConfirm: 'Dismiss',
        buttonThemeConfirm: 'blue',
        onConfirm: () => this.dialogService.hideDialog()
      });
      return false;
    }
    return true;
  }
}
