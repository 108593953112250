@if (isVisible) {
  <div class="modal">
    <div class="modal__content">
      <ng-content></ng-content>
    </div>
    <ysh-button
      class="modal__close"
      matTooltip="Close"
      matTooltipPosition="left"
      theme="smoke"
      icon="close"
      [isControl]="true"
      (click)="didTapClose()"
    />
  </div>
}
