import { Component, Input, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { Service } from '../../../models/service';
import { TimeSlot } from '../../../models/time-slot';
import { FlowPage } from '../../flow-director';
import { ServiceGroup } from '../../../models/service-group';
import { ModalService } from '../../../services/ui/modal/modal.service';
import { TimeSlotService } from '../../../services/api/time-slot/time-slot.service';
import { ServiceSelectorPage } from '../service-selector/service-selector.page';
import { CommonModule } from '@angular/common';
import { YshButtonComponent } from '../../../components/ysh-button/ysh-button.component';
import { YshFlowNavBarComponent } from '../../../components/ysh-flow-nav-bar/ysh-flow-nav-bar.component';
import { YshFlowContentComponent } from '../../../components/ysh-flow-content/ysh-flow-content.component';
import { YshFlowFooterComponent } from "../../../components/ysh-flow-footer/ysh-flow-footer.component";
import { YshDotsComponent } from '../../../components/ysh-dots/ysh-dots.component';
import { MatIconModule } from '@angular/material/icon';
import { YshServiceCardComponent } from "../../../components/ysh-service-card/ysh-service-card";
import { AnalyticsService } from '../../../services/analytics/analytics.service';
import { YshButtonControlComponent } from "../../../components/ysh-button-control/ysh-button-control.component";

export interface AppointmentBuilderPageProps {
  services: Service[];
  serviceGroup: ServiceGroup;
}

export interface AppointmentBuilderPageForm {
  services: Service[];
  slots: TimeSlot[];
  slotCount: number;
  appointmentDuration: number;
}

@Component({
  standalone: true,
  selector: 'appointment-builder',
  templateUrl: 'appointment-builder.page.html',
  styleUrls: ['appointment-builder.page.scss'],
  imports: [
    CommonModule,
    YshButtonComponent,
    YshFlowNavBarComponent,
    YshFlowContentComponent,
    YshFlowFooterComponent,
    YshDotsComponent,
    MatIconModule,
    YshServiceCardComponent,
    YshButtonControlComponent,
  ]
})
export class AppointmentBuilderPage implements OnInit, FlowPage {
  @Input() onDismiss: () => void;
  @Input() onComplete: (services: Service[], slots: TimeSlot[]) => void;
  @Input() props: AppointmentBuilderPageProps;

  form: AppointmentBuilderPageForm;
  loading = false;
  error: Nullable<string>;

  constructor(
    private analytics: AnalyticsService,
    private modalService: ModalService, 
    private timeSlotService: TimeSlotService) {}

  ngOnInit() {
    this.analytics.trackView('AppointmentBuilderPage');
    this.form = {
      services: this.props.services || [],
      slots: [],
      appointmentDuration: 0,
      slotCount: 0,
    };

    if (this.form.services.length){
      this.getTimeSlots();
    }
  }

  // Data

  private pendingSubscription: Subscription;

  getTimeSlots() {
    const serviceUids: string[] = this.form.services.map(item => item.uid);
    if (this.pendingSubscription) {
      this.pendingSubscription.unsubscribe();
    }
    this.loading = true;
    this.error = null;
    this.pendingSubscription = this.timeSlotService
      .getMany({to: null, from: null, serviceUids})
      .pipe(finalize(() => (this.loading = false)))
      .subscribe(
        (slots) => {
          this.form.slots = slots.items;
          this.form.slotCount = slots.items.filter((slot) => slot.available).length;
        },
        (error) => (this.error = error)
      );
  }

  // Actions

  didTapBack() {
    this.onDismiss?.();
  }

  didTapNext() {
    this.onComplete(this.form.services, this.form.slots);
  }

  didTapAddVehicle() {
    this.modalService.present({
      component: ServiceSelectorPage,
      componentProps: {
        onComplete: (service) => this.didSelectService(service),
      },
      props: {
        services: this.props.serviceGroup.services,
      },
    });
  }

  didSelectService(service: Service) {
    this.form.services.push(service);
    this.updateDuration();
    this.getTimeSlots();
    this.modalService.dismiss();
  }

  didTapDeleteService(index: number) {
    this.form.services.splice(index, 1);
    this.getTimeSlots();
    this.updateDuration();
  }

  // Helpers

  private updateDuration() {
    this.form.appointmentDuration = this.form.services.reduce(
      (sum, service) => sum + service.stopDuration,
      0
    );
  }

  get validates() {
    return this.form.services.length && this.form.slotCount && !this.error && !this.loading;
  }
}
