import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { YshButtonComponent } from '../ysh-button/ysh-button.component';

@Component({
  selector: 'ysh-split-layout',
  templateUrl: './ysh-split-layout.component.html',
  styleUrl: './ysh-split-layout.component.scss',
  standalone: true,
  imports: [
    MatIconModule,
    MatTooltipModule,
    YshButtonComponent,
  ],
})
export class YshSplitLayoutComponent {
  @Input({ required: true }) theme: 'blue' | 'space' | 'smoke';
  @Input() hasLogo?: boolean;
  @Input() hasBackButton?: boolean;
  @Input() srcImage?: string;
  @Input() containImage?: boolean;
  @Input() reverseImage?: boolean;
  @Input() reverseLayout?: boolean;
  @Output() onBack = new EventEmitter();

  get srcForLogo(): string {
    return `/assets/logos/yoshi-logo-${this.theme === 'smoke' ? 'space' : 'white'}.svg`;
  }

  get srcForImage(): string {
    return this.srcImage || `/assets/images/split-layout-theme-${this.theme}.svg`;
  }
  
  handleBack() {
    this.onBack.emit();
  }
}
