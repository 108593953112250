import { BaseEntity, MemoizeGetters, Transform } from "./base";
import { toEntity, toNumber } from "./base/transform";

export type TyleType = 'single_value' | 'chart';
export type ChartType = 'bar' | 'line' | 'pie' | 'doughnut';
export type DataFormat = 'number' | 'currency';


export interface TileLayout {
  spanX?: number | string,
  spanY?: number | string,
}

@MemoizeGetters
export class TileDataSets extends BaseEntity{
  label?: string;

  @Transform(data => data.map(toNumber))
  data: number[];
  backgroundColor?: string | string[];
  borderColor?: string;
}

@MemoizeGetters
export class TileData extends BaseEntity{
  labels: string[];

  @Transform(toEntity(TileDataSets))
  datasets: TileDataSets[];
}

@MemoizeGetters
export class Tile extends BaseEntity {
  title: string; 
  subtitle: string;
  infotip?: string;
  type: TyleType;
  chartType?: ChartType
  value?: string;

  @Transform(toEntity(TileData))
  data?: TileData;
  dataFormat?: DataFormat;
  layout?: TileLayout;
}

@MemoizeGetters
export class Dashboard extends BaseEntity{
  title: string;

  @Transform(toEntity(Tile))
  tiles: Tile[];
  numColumns: number; 
  numRows: number;
}
