import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrl: './error.component.scss',
  standalone: true,
  imports: [],
})
export class ErrorComponent {
  private static readonly messages = {
    '401': 'Access Unauthorized.',
    '403': 'Access Forbidden.',
    '404': 'Page Not Found.',
    '500': 'Internal Server Error.',
  };

  constructor(private route: ActivatedRoute) {}

  get message(): string {
    const errorType = this.route.snapshot.paramMap.get('errorType');
    return ErrorComponent.messages[errorType] || 'Oops! An Error Occurred.';
  }
}
