<form class="form" [formGroup]="formGroup" (ngSubmit)="handleSubmit()">
  <h2 class="form__heading" [innerHTML]="formHeading"></h2>
  @if (formCaption) { 
    <p class="form__caption">{{ formCaption }}</p>
  }
  @if (formErrorMessages.length) {
    <p class="form__errors">
      @for (message of formErrorMessages; track $index) {
        <span>{{ message }}</span>
      }
    </p>
  }
  @for (formField of formFields; track formField) {
    <mat-form-field class="form__field" [class.half]="formField.half" [class.tappable]="formField.onTap" (mousedown)="handleTapField($event,formField)">
      <mat-label>{{ formField.label }}</mat-label>

      <input matInput [formControlName]="formField.name" [type]="formField.type" [readonly]="formField.readonly" [tabindex]="(formField.readonly || formField.onTap) && -1" (blur)="markFieldDirty(formField.name)">
      @if(formField.icon) {
        <mat-icon size-medium matSuffix>{{ formField.icon }}</mat-icon>
      }
      @if(fieldErrorMessages[formField.name]){
        <mat-error>{{fieldErrorMessages[formField.name]}}</mat-error>
      }
      @if (formField.hint) {
        <mat-hint>{{formField.hint}}</mat-hint>
      }
    </mat-form-field>
  }
  @if (onAddField) {
    <div class="form__add-remove">
      @if (canAddField) {
        <button type="button" (click)="handleAddField()">
          <mat-icon>add_circle_outline</mat-icon> Add
        </button>
      }
      @if (canRemoveField) {
        <button type="button" (click)="handleRemoveField()">
          <mat-icon>remove_circle_outline</mat-icon> Remove
        </button>
      }
    </div>
  }
  <ysh-button
    class="form__submit"
    [label]="formSubmitLabel"
    [loading]="formSubmitting"
    [disabled]="formSubmitting || !formGroup.valid"
  />
  @if (formLinks) {
    @for (formLink of formLinks; track formLink) {
      <a class="form__link" [routerLink]="['/',formLink.route]" [queryParamsHandling]="formLink.queryParamsHandling">{{ formLink.label }}</a>
    }
  }
</form>
