import { BaseEntity, Default, MemoizeGetters, Transform, toDate, toEntity  } from "./base";
import moment from "moment";
import { UserAddress } from "./user_address";
import { Company } from "./company";
import { Affilation } from "./affiliation";

export interface AddressParams {
  locationName?: string | null;
  lat?: number | null;
  lng?: number | null;
  zipCode?: string | null;
  requestSource?: string | null;
}

export function addressParamsForGooglePlaceData(
  data: google.maps.places.PlaceResult
): AddressParams {
  const zipcodeComponent = data.address_components?.find((i) => i.types[0] === 'postal_code');
  const hasName = data.name && !data.formatted_address?.includes(data.name);
  const locationName = hasName ? `${data.name}, ${data.formatted_address}` : data.formatted_address;
  return {
    locationName,
    lat: data.geometry?.location.lat(),
    lng: data.geometry?.location.lng(),
    zipCode: zipcodeComponent?.long_name,
  };
}


@MemoizeGetters
export class User extends BaseEntity {
  firstName?: string;
  lastName?: string;
  email?: string;
  hasValidPaymentMethod: boolean;
  phone?: string;

  @Transform(toEntity(Affilation))
  affiliations: Affilation[];

  @Transform(toDate)
  createdAt?: Date;

  @Transform(toEntity(UserAddress))
  lastUserAddress?: UserAddress;

  @Default([])
  regionUids: string[];

  get companies(): Company[] {
    return this.affiliations.map(affiliation => affiliation.company);
  }

  get name(): string {
    return `${this.firstName || ''} ${this.lastName || ''}`.trim();
  }

  get phoneFormatted(): string {
    // (123) 456-7890
    return this.phone?.replace(/\D/g, '').replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
  }

  get createdAtFormatted() {
    return moment(this.createdAt).format('LL');
  }

  get primaryRegionUid(): string {
    return this.hasRegions && this.regionUids[0];
  }

  get hasRegions(): boolean {
    return Boolean(this.regionUids.length);
  }

  get timeZoneLong(): string {
    if (!this.lastUserAddress?.address){
      return null
    }
    const abbrs = {
      EST : 'Eastern Standard Time',
      EDT : 'Eastern Daylight Time',
      CST : 'Central Standard Time',
      CDT : 'Central Daylight Time',
      MST : 'Mountain Standard Time',
      MDT : 'Mountain Daylight Time',
      PST : 'Pacific Standard Time',
      PDT : 'Pacific Daylight Time',
    };
    moment.fn.zoneName = function() {
      let abbr = this.zoneAbbr();
      return abbrs[abbr] || abbr;
    };
    return moment().tz(this.lastUserAddress.address.timeZone).format('zz');
  }
}
