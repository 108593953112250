<ysh-split-layout theme="blue" [hasLogo]="true">
  <ysh-form
    formHeading="Get Started"
    formLinkLabel="Have account? Login"
    formLinkHref="/login"
    formSubmitLabel="Create Account"
    [formFields]="formFields"
    [onSubmit]="handleSignup.bind(this)"
  ></ysh-form>
</ysh-split-layout>
