import { Injectable } from "@angular/core";
import { Report } from "../models/report";
import { ApiService, FileFormat } from "./api.service";
import { AuthService } from "./auth/auth.service";

@Injectable({
  providedIn: 'root',
})
export class ReportService {
  constructor(private apiService: ApiService) {}


  getMany() {
    const url = `/users/${AuthService.USER_UID}/reports`;
    return this.apiService.getMany(url, {}, Report);
  }

  get(reportName: string, params?: any) {
    const url =  `/users/${AuthService.USER_UID}/reports/${reportName}`;
    return this.apiService.get(url, Report, {
      ...params
    });
  }

  getData(reportName: string, params: any) {
    const url =  `/users/${AuthService.USER_UID}/reports/${reportName}/data`;
    return this.apiService.getMany(url, {
      ...params
    });
  }

  download(reportName: string, format: FileFormat, params: any = {}) {
    const url =  `/users/${AuthService.USER_UID}/reports/${reportName}/download`;
    return this.apiService.download(url, reportName, format, {
      ...params
    });
  }
}
