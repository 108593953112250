<ysh-flow-nav-bar></ysh-flow-nav-bar>

<ysh-flow-content>
  <div class="intro">
    <h2 class="intro__heading">{{introContent.heading}}</h2>
    <p class="intro__message">{{introContent.message}}</p>
  </div>
  <div class="body">
    @for (order of this.props.orders; track $index) {
      <ysh-service-card 
        title="{{order.date | date: 'EEEE, MMM d, y'}}"
        details="{{order.service.durationString}}, ${{order.service.price}}"
        detailsIcon="schedule"
        description="{{order.service.title}}"
        [deletable]="true"
        (onDelete)="didTapCancel(order)"
      />
    }
  </div>
</ysh-flow-content>

<ysh-flow-footer>
  <ysh-button block
    label="Continue to Portal"
    (click)="didTapDone()"
  >
  </ysh-button>
</ysh-flow-footer>
