import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AppRoutes, companyRoute } from '../../app.routes';
import { UserService } from '../../services/api/user/user.service';
import { CompanyService } from '../../services/api/company/company.service';
import { Company } from '../../models/company';

export interface MenuItem {
  label: string,
  icon?: string,
  isActive?: boolean;
}

@Component({
  selector: 'ysh-main-header',
  templateUrl: './ysh-main-header.component.html',
  styleUrl: './ysh-main-header.component.scss',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    MatIconModule,
    MatTooltipModule
  ],
})
export class YshMainHeaderComponent {
  @Input() userName: string;
  @Input() activeMobileMenu: boolean;
  @Output() onTapLogout = new EventEmitter();
  @Output() onTapMobileMenu = new EventEmitter();

  appRoutes = AppRoutes;
  isCompanyMenuOpen: boolean = false;
  emailSupport = "mailto:support@yoshimobility.com?subject=Portal%20Help";

  constructor(
    public userService: UserService,
    public companyService: CompanyService,
    public router: Router,
  ){}

  toggleCompanyMenu(event: Event) {
    if (event.currentTarget === event.target) {
      this.isCompanyMenuOpen = !this.isCompanyMenuOpen;
    }
  }

  didTapCompany = (company: Company) => {
    this.companyService.setSelectedCompany(company);
    this.isCompanyMenuOpen = false;
    window.location.href = companyRoute(AppRoutes.Dashboard, company);
  }

  didTapLogout() {
    this.onTapLogout.emit();
  }

  didTapMobileMenu() {
    this.onTapMobileMenu.emit();
  }
}
