<header class="header" [ngClass]="{'active-mobile-menu': activeMobileMenu}">
  <div class="header__logo">
    <img src="/assets/logos/yoshi-logo-space.svg">
  </div>
  <div class="header__mobile-menu-button" (click)="didTapMobileMenu()">
    @if (activeMobileMenu) {
      <mat-icon>menu_open</mat-icon> Close
    } @else {
      <mat-icon>menu</mat-icon> Menu
    }
  </div>
  <nav class="header__nav">
    <a class="header__nav__item" routerLink="/account" matTooltip="View Account">
      <mat-icon>account_circle</mat-icon> {{userName}}
    </a>
    <a class="header__nav__item" (click)="didTapLogout()">
      <mat-icon>logout</mat-icon> Logout
    </a>
    <a class="header__nav__item" href="">
      <mat-icon>email</mat-icon> Help
    </a>
  </nav>
</header>
