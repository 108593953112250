import { Injectable } from "@angular/core";
import { ApiService, GetManyParams } from "../../api.service";
import { Delivery } from "../../../models/delivery";
import { AuthService } from "../../auth/auth.service";

@Injectable({
  providedIn: 'root',
})
export class DeliveryService {
  constructor(private apiService: ApiService) {}

  get(uid: string) {
    const url  = `/deliveries/${uid}`;
    return this.apiService.get(url, Delivery);
  }

  getMany(params: GetManyParams, accountUid?: string) {
    const url = `/users/${accountUid || AuthService.USER_UID}/deliveries`;
    return this.apiService.getMany(url, { ...params }, Delivery);
  }

  cancel(uid: string) {
    const url = `/deliveries/${uid}/cancel`
    return this.apiService.post(url, Delivery, { cancellationReason: 'skip_next_delivery'});
  }
}
