<ysh-flow-nav-bar>
  <ysh-button-control type="back" position="top-left" (click)="didTapBack()"/>
</ysh-flow-nav-bar>
<ysh-flow-content>
  <div class="intro">
    <h1 class="intro__heading">
      @if (form.services.length){
        Vehicle added! Do you want to add more vehicles to your inspection appointment?
      } @else {
        Select a service option below!
      }
    </h1> 
    @if (form.services.length) {
      <p class="intro__status" [ngClass]="{'unavailable': !form.slotCount || error }">
        @if (loading) {
          <span class="intro__status__loading">
            Loading appointment times for your current selection:
            <ysh-dots />
          </span>
        } @else if (form.services.length && !error) {
          <em>{{form.slotCount}} appointment times are available</em> for the
          <b>{{form.appointmentDuration}} minutes</b> required for your current selection:
        } @else if (error) {
          <em>Error: {{error}}</em>
        }
      </p>
    }
  </div>
  <div class="body">
    @for (service of form.services; track $index) {
      <ysh-service-card 
        title="{{service.title}}"
        details="{{service.durationString}}, ${{service.price}}"
        detailsIcon="schedule"
        [deletable]="form.services.length > 1"
        (onDelete)="didTapDeleteService($index)"
      />
    }
    <button class="body__btn-add" (click)="didTapAddVehicle()">
      @if (form.services.length) {
        Add another service
      } @else {
        Add first vehicle service
      }
    </button>
  </div>
</ysh-flow-content>
<ysh-flow-footer class="footer">
  <ysh-button block class="footer__btn-next" 
    [disabled]="!validates || loading"
    [loading]="loading" (click)="didTapNext()" 
    [label]="!validates ? 'No Availability' : 'Next'">
  </ysh-button>
</ysh-flow-footer>

