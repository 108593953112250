import { Component } from '@angular/core';
import { Validators } from '@angular/forms';
import { firstValueFrom } from 'rxjs';
import { UserService } from '../../services/user/user.service';
import { FormField, YshFormComponent } from '../../components/ysh-form/ysh-form.component';
import { YshSplitLayoutComponent } from "../../components/ysh-split-layout/ysh-split-layout.component";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss',
  standalone: true,
  imports: [YshFormComponent, YshSplitLayoutComponent],
})
export class LoginComponent {

  formFields: FormField[] = [
    {
      name: 'email',
      label: 'Email Address',
      type: 'email',
      icon: 'alternate_email',
      validators: [Validators.required, Validators.email]
    },
    { 
      name: 'password',
      label: 'Password',
      type: 'password',
      icon: 'lock',
      validators: [Validators.required]
    },
  ];

  constructor(private userService: UserService) {}

  async handleLogin(formValues: any): Promise<void> {  
    try {
      await firstValueFrom(
        this.userService.login(formValues.email, formValues.password)
      );
    } catch (error) {
      console.error(error);
      throw error; // Throw error for YshFormComponent to handle
    }
  }
}
