import { BaseEntity, Default, MemoizeGetters, Transform, toDate  } from "./base";
import moment from "moment";

export interface AddressParams {
  locationName?: string | null;
  lat?: number | null;
  lng?: number | null;
  zipCode?: string | null;
  requestSource?: string | null;
}

export function addressParamsForGooglePlaceData(
  data: google.maps.places.PlaceResult
): AddressParams {
  const zipcodeComponent = data.address_components?.find((i) => i.types[0] === 'postal_code');
  const hasName = data.name && !data.formatted_address?.includes(data.name);
  const locationName = hasName ? `${data.name}, ${data.formatted_address}` : data.formatted_address;
  return {
    locationName,
    lat: data.geometry?.location.lat(),
    lng: data.geometry?.location.lng(),
    zipCode: zipcodeComponent?.long_name,
  };
}


@MemoizeGetters
export class User extends BaseEntity {
  firstName?: string;
  lastName?: string;
  email?: string;
  phone?: string;
  latestTimeZone?: string;

  @Transform(toDate)
  createdAt?: Date;

  @Default([])
  regionUids: string[];

  get name(): string {
    return `${this.firstName || ''} ${this.lastName || ''}`.trim();
  }

  get createdAtFormatted() {
    return moment(this.createdAt).format('LL');
  }

  get primaryRegionUid(): string {
    return this.hasRegions && this.regionUids[0];
  }

  get hasRegions(): boolean {
    return Boolean(this.regionUids.length);
  }

  get timeZoneLong(): string {
    if (!this.latestTimeZone){
      return null
    }
    const abbrs = {
      EST : 'Eastern Standard Time',
      EDT : 'Eastern Daylight Time',
      CST : 'Central Standard Time',
      CDT : 'Central Daylight Time',
      MST : 'Mountain Standard Time',
      MDT : 'Mountain Daylight Time',
      PST : 'Pacific Standard Time',
      PDT : 'Pacific Daylight Time',
    };
    moment.fn.zoneName = function() {
      let abbr = this.zoneAbbr();
      return abbrs[abbr] || abbr;
    };
    return moment().tz(this.latestTimeZone).format('zz');
  }
}
