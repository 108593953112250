<div class="company-page">
  <ysh-page-header title="Company Overview">
    @if (allowInviteTeamMembers) {
      <ysh-button
      label="Invite Members"
      theme="blue"
      size="small"
      (click)="handleInviteMembers()"
    />
    }
  </ysh-page-header>
  <div class="panels">
    <ysh-panel
      class="panel"
      heading="Information"
      padding="left-right-bottom">

      <ysh-traits [traits]="companyInfo" imageSrc="assets/images/avatar-default-space.png"/>
    </ysh-panel>

    @if (allowViewCreditCard) {
      <ysh-panel
      class="panel"
      heading="Payment"
      [actionLabel]="companyCreditCardInfo ? 'Change' : 'Add Card'"
      (onAction)="handleAddCreditCard()"
      padding="left-right-bottom">
      @if(companyCreditCardInfo) {
        <ysh-traits [traits]="companyCreditCardInfo"/>
      }
    </ysh-panel>
    }

  </div>

  @if (allowViewTeamMembers) {
    <h3>Team Members</h3>
    <br>
    <ysh-table-set
      [dataSource]="dataSourceMembers"
      (onItemTap)="handleNavigateToMember($event)"
    />
  }
</div>
