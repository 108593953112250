<div class="login-page">
  <img class="logo" src="/assets/logos/yoshi-logo-space.svg">
  <form class="form" [formGroup]="loginForm" (ngSubmit)="didTapLogin()">
    <h2 class="form__heading">Login</h2>
    
    @if (loginError) { 
      <p class="form__error">{{ LOGIN_ERROR_MESSAGE }}</p>
    }
    
    <mat-form-field class="form__field">
      <mat-label>Email</mat-label>
      <input matInput formControlName="email" type="email" required>
      <mat-icon matSuffix>alternate_email</mat-icon>
    </mat-form-field>

    <mat-form-field class="form__field">
      <mat-label>Password</mat-label>
      <input matInput formControlName="password" type="password" required>
      <mat-icon matSuffix>lock</mat-icon>
    </mat-form-field>
    
    <button class="form__button" mat-flat-button [disabled]="loggingIn">
      @if (loggingIn) {
        <mat-spinner diameter="20"/>
      } @else { 
        Login
      } 
    </button>

    <a class="form__contact" href="{{ LOGIN_SUPPORT_EMAIL }}" target="_blank">
      Trouble logging in?
    </a>
  </form>
</div>
