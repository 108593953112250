import { Component, Input, OnInit } from '@angular/core';
import { ComponentWithInputs, TableDataSouce, YshTableSetComponent } from '../../components/ysh-table-set/ysh-table-set.component';
import { Order } from '../../models/order';
import { OrderService } from '../../services/api/order/order.services';
import { MatListModule } from '@angular/material/list';
import { Vehicle } from '../../models/vehicle';
import { VehicleService } from '../../services/api/vehicle/vehicle.service';
import { YshPageHeaderComponent } from '../../components/ysh-page-header/ysh-page-header.component';
import { YshAttributesComponent, AttributesListItem } from "../../components/ysh-attributes/ysh-attributes.component";
import { YshBadgeComponent } from '../../components/ysh-badge/ysh-badge.component';
import { Router } from '@angular/router';
import { AppRoutes, companyRoute } from '../../app.routes';
import { CompanyService } from '../../services/api/company/company.service';
import { first } from 'rxjs';

@Component({
  selector: 'app-vehicle',
  templateUrl: './vehicle.component.html',
  styleUrl: './vehicle.component.scss',
  standalone: true,
  imports: [
    MatListModule,
    YshTableSetComponent,
    YshPageHeaderComponent,
    YshAttributesComponent,
  ],
})
export class VehicleComponent implements OnInit {

  @Input() uid: string;

  dataSource: TableDataSouce<Order>;
  vehicle?: Vehicle;
  vehicleAttributes: AttributesListItem[];
  backLinkRoute: string;

  constructor(private orderService: OrderService, private vehicleService: VehicleService, private router: Router, private companyService: CompanyService) {}

  ngOnInit(): void {
    this.dataSource = {
      title: "",
      columns: [
        { field: "timeCompletedString", title: 'Time Completed'},
        { field: "addressString", title: 'Address', cellSize: 'large'},
        { field: "serviceString", title: 'service', cellSize: 'large'},
        { field: "status", title: 'status', cellSize: 'small', component: YshBadgeComponent}
      ],
      load: (state) => this.orderService.getManyForVehicle(this.uid, state),
      displayRowNav: true,
      filters: [],
      actions: [],
    }
    this.getCompany();
    this.getVehicle();
  }

  getCompany() {
    this.companyService.currentCompany$.pipe(first()).subscribe(company => {
      this.backLinkRoute = '/' + companyRoute(AppRoutes.Vehicles, company);
    });
  }

  getVehicle() {
    this.vehicleService.get(this.uid).subscribe(vehicle => {
      this.vehicle = vehicle;
      this.vehicleAttributes = [
        { label: 'Year/Make/Model', value: this.vehicle?.description },
        { label: 'Color', value: this.vehicle?.color },
        { label: 'License', value: this.vehicle?.license },
        { label: 'VIN', value: this.vehicle?.vin },
        { label: 'Serial Number', value: this.vehicle?.serialNumber },
        { label: 'Last Inspection', value: this.vehicle?.lastInspectionCompletedAt },
      ]
    });
  }

  didTapItem(item: Order){
    this.router.navigate([AppRoutes.Orders, item.uid]);
  }
}
