import { Component } from '@angular/core';
import { Validators } from '@angular/forms';
import { delay, firstValueFrom } from 'rxjs';
import { UserService } from '../../services/user/user.service';
import { FormField, YshFormComponent } from '../../components/ysh-form/ysh-form.component';
import { YshSplitLayoutComponent } from "../../components/ysh-split-layout/ysh-split-layout.component";

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrl: './signup.component.scss',
  standalone: true,
  imports: [YshFormComponent, YshSplitLayoutComponent],
})
export class SignupComponent {

  formFields: FormField[] = [
    { 
      name: 'firstName',
      label: 'First Name',
      type: 'text',
      half: true,
      validators: [Validators.required] 
    },
    { 
      name: 'lastName',
      label: 'Last Name',
      type: 'text',
      half: true,
      validators: [Validators.required] 
    },
    { 
      name: 'phone',
      label: 'Phone Number',
      type: 'tel',
      icon: 'phone',
      validators: [Validators.required]
    },
    {
      name: 'email',
      label: 'Email Address',
      type: 'email',
      icon: 'alternate_email',
      validators: [Validators.required, Validators.email]
    },
    { 
      name: 'password',
      label: 'Password',
      type: 'password',
      icon: 'lock',
      validators: [Validators.required]
    },
  ];

  constructor(private userService: UserService) {}

  async handleSignup(formValues: any): Promise<void> {  
    try {
      await firstValueFrom(
        this.userService.signup(
          formValues.firstName,
          formValues.lastName,
          formValues.phone,
          formValues.email,
          formValues.password
        ).pipe(delay(500))
      );
      await firstValueFrom(
        this.userService.login(formValues.email, formValues.password)
      );
    } catch (error) {
      console.error(error);
      throw error; // Throw error for YshFormComponent to handle
    }
  }
}
