import memoizee from 'memoizee';

/**
 * BaseEntity class decorator to find and cache all getter methods
 * @param  {Function} constructor
 */
export function MemoizeGetters(constructor: Function) {
  const descriptors = Object.getOwnPropertyDescriptors(constructor.prototype);
  for (let key in descriptors) {
    const descriptor = descriptors[key];
    if (descriptor.get) {
      const getter = descriptor.get;
      const memoized = memoizee((context, key) => {
        const func = getter.bind(context);
        return func();
      });
      Object.defineProperty(
        constructor.prototype,
        key,
        Object.assign({}, descriptor, {
          get: function memoizedGetter() {
            return memoized(this, key);
          },
        })
      );
    }
  }
}

export function MemoizeGetter(): MethodDecorator {
  return (target: any, propertyKey: string | symbol, descriptor: PropertyDescriptor) => {
    const getter = descriptor.get;
    if (!getter) {
      throw new Error(`${String(propertyKey)} getter is undefined`);
    }
    const memoized = memoizee((context) => {
      const func = getter.bind(context);
      return func();
    });
    descriptor.get = function () {
      return memoized(this);
    };
    return descriptor;
  };
}
