@if (isVisible) {
  <div class="modal">
    <div class="modal__content">
      <ng-content></ng-content>
    </div>
    <div class="modal__close"
      matTooltip="Close"
      matTooltipPosition="left"
      matTooltipShowDelay="1000"
      (click)="didTapClose()"
    >
      <mat-icon>close</mat-icon>
    </div>
  </div>
}
