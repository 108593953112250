import { Component, Input } from '@angular/core';
import { YshModalComponent } from "../ysh-modal/ysh-modal.component";
import { MatIconModule } from '@angular/material/icon';
import { YshCarouselComponent } from "../ysh-carousel/ysh-carousel.component";

export interface GalleryImage {
  src: string,
  caption?: string,
}

@Component({
  selector: 'ysh-gallery',
  templateUrl: './ysh-gallery.component.html',
  styleUrl: './ysh-gallery.component.scss',
  standalone: true,
  imports: [YshModalComponent, YshCarouselComponent, MatIconModule],
})
export class YshGalleryComponent {
  @Input() images: GalleryImage[] = [];

  modalVisible: boolean = false;
  carouselActiveItem: number = 0;

  toggleModal(index?: number) {
    this.modalVisible = !this.modalVisible;
    this.carouselActiveItem = index;
  }
}
