import { CommonModule } from "@angular/common";
import { Component, Input } from "@angular/core";
import { YshCellComponent } from "../../../../components/ysh-cell.component";
import { Invoice } from "../../../../models/invoice";

@Component({
  selector: 'ysh-invoice-status-cell',
  templateUrl: './ysh-invoice-status-cell.component.html',
  styleUrl: './ysh-invoice-status-cell.component.scss',
  standalone: true,
  imports: [
    CommonModule
  ],
})
export class YshInvoiceStatusCellComponent implements YshCellComponent {
  @Input() value: Invoice;
} 