<form class="form" [formGroup]="formGroup" (ngSubmit)="handleSubmit()">
  <h2 class="form__heading">{{ formHeading }}</h2>
  @if (formError) { 
    <p class="form__error">{{ formError }}</p>
  }
  @for (formField of formFields; track formField) {
    <mat-form-field class="form__field" [class.half]="formField.half">
      <mat-label>{{ formField.label }}</mat-label>
      <input matInput [formControlName]="formField.name" [type]="formField.type">
      @if(formField.icon) {
        <mat-icon matSuffix>{{ formField.icon }}</mat-icon>
      }
    </mat-form-field>
  }
  <ysh-button
    class="form__submit"
    [label]="formSubmitLabel"
    [spinner]="formSubmitting"
    [disabled]="formSubmitting"
  />
  @if (formLinkHref && formLinkLabel) { 
    <a class="form__link" href={{formLinkHref}}>{{ formLinkLabel }}</a>
  }
</form>
