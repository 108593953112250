import { Dashboard } from '../../models/dashboard';
import { ApiService } from '../api.service';
import { Injectable } from '@angular/core';
import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root',
})
export class DashboardService {
  constructor(private apiService: ApiService) {}

  get(name: string) {
    const url = `/users/${AuthService.USER_UID}/dashboards/${name}`;
    return this.apiService.get(url, Dashboard);
  }
}
