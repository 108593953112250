import { Component, OnInit } from '@angular/core';
import { User } from '../../models/user';
import { AttributesListItem, YshAttributesComponent } from '../../components/ysh-attributes/ysh-attributes.component';
import { YshPageHeaderComponent } from "../../components/ysh-page-header/ysh-page-header.component";
import { UserService } from '../../services/user/user.service';

@Component({
  selector: 'app-account',
  standalone: true,
  imports: [YshPageHeaderComponent, YshAttributesComponent],
  templateUrl: './account.component.html',
  styleUrl: './account.component.scss'
})
export class AccountComponent implements OnInit {
  currentUser: User;
  currentUserAttributes: AttributesListItem[];

  constructor(private userService: UserService) {}
  
  ngOnInit() {
    this.getCurrentUser();
  }

  getCurrentUser() {
    this.userService.currentUser$.subscribe(user => {
      this.currentUser = user;
      this.currentUserAttributes = [
        { label: 'User Name', value: this.currentUser?.name },
        { label: 'Email Address', value: this.currentUser?.email },
        { label: 'Phone Number', value: this.currentUser?.phone },
        { label: 'Account Created', value: this.currentUser?.createdAtFormatted },
      ]
    });
  }
}
