import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { FormatFieldService } from '../../services/format-field.service';
import { YshButtonComponent } from "../ysh-button/ysh-button.component";

export interface FormField {
  name: string;
  label: string;
  type: string;
  /** Name from Material Icons */
  icon?: string;
  /** Half width field */
  half?: boolean;
  validators?: any[];
}

@Component({
  selector: 'ysh-form',
  templateUrl: './ysh-form.component.html',
  styleUrls: ['./ysh-form.component.scss'],
  standalone: true,
  imports: [
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    YshButtonComponent,
  ],
})
export class YshFormComponent implements OnInit {
  @Input() formHeading = 'Form';
  @Input() formSubmitLabel = 'Submit';
  @Input() formErrorMessage = 'Error. Please try again.';
  @Input() formLinkLabel?: string;
  @Input() formLinkHref?: string;
  @Input() formFields: FormField[] = [];
  @Input() onSubmit: (formValues: any) => Promise<void>;

  formGroup: FormGroup;
  formError: string | undefined;
  formSubmitting = false;

  constructor(
    private formBuilder: FormBuilder,
    private formatFieldService: FormatFieldService,
  ) {}

  ngOnInit(): void {
    this.formGroup = this.formBuilder.group(
      Object.fromEntries(
        this.formFields.map(({ name, validators = [] }) => [name, ['', validators]])
      )
    );

    this.formatFieldService.phoneField(this.formGroup, this.formFields);
  }

  async handleSubmit() {
    this.formSubmitting = true;
    try {
      await this.onSubmit(this.formGroup.value);
    } catch (err: any) {
      this.formError = err?.error?.errors?.[0]?.message || this.formErrorMessage;
    } finally {
      this.formSubmitting = false;
    }
  }
}
