<ysh-flow-nav-bar>
  <ysh-button-control type="exit" position="top-left" (click)="handleDismiss()"/>
</ysh-flow-nav-bar>
<ysh-flow-content max-width-none>
  <ysh-select-list
    heading="Select Member Role"
    [groupItems]="roleItems"
    [selection]="roleSelection"
    [selectionPreserve]="true"
    (selectionChange)="handleSelectionChange($event)"
    [isMultiSelect]="false"
    [isSearchable]="false"
  />
</ysh-flow-content>
