import { Component, OnInit } from '@angular/core';
import { TableDataSouce, TableFilter, YshTableSetComponent } from '../../components/ysh-table-set/ysh-table-set.component';
import { InvoiceService } from '../../services/invoice/invoice.service';
import { Invoice } from '../../models/invoice';
import { YshInvoiceStatusCellComponent } from './components/ysh-invoice-status-cell/ysh-invoice-status-cell.component';

@Component({
  selector: 'app-invoices',
  templateUrl: './invoices.component.html',
  styleUrl: './invoices.component.scss',
  standalone: true,
  imports: [
    YshTableSetComponent,
  ],
})
export class InvoicesComponent implements OnInit{

  dataSource: TableDataSouce<Invoice>;

  constructor(private invoiceService: InvoiceService){}

  ngOnInit(): void {
    this.dataSource = {
      title: "Invoices",
      columns: [
        { field: "startDate", title: 'Start Date', sortable: true },
        { field: "endDate", title: 'End Date', sortable: true },
        { field: "amountString", title: 'amount', sortable: true },
        { title: 'status', component: YshInvoiceStatusCellComponent }
      ],
      load: (state) => this.invoiceService.getMany(state),
      filters: [TableFilter.StartEndDate],
      actions: [],
    }
  }
}
