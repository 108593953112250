import { Component, Input, OnInit } from '@angular/core';
import { TableDataSouce, YshTableSetComponent } from '../../components/ysh-table-set/ysh-table-set.component';
import { YshOrderStatusCellComponent } from '../schedule/components/ysh-order-status-cell/ysh-order-status-cell.component';
import { Order } from '../../models/order';
import { OrderService } from '../../services/order/order.services';
import { MatListModule } from '@angular/material/list';
import { Vehicle } from '../../models/vehicle';
import { VehicleService } from '../../services/vehicle/vehicle.service';
import { YshPageHeaderComponent } from '../../components/ysh-page-header/ysh-page-header.component';
import { YshAttributesComponent, AttributesListItem } from "../../components/ysh-attributes/ysh-attributes.component";
import { Router } from '@angular/router';
import { AppRoutes } from '../../app.routes';

@Component({
  selector: 'app-vehicle',
  templateUrl: './vehicle.component.html',
  styleUrl: './vehicle.component.scss',
  standalone: true,
  imports: [
    MatListModule,
    YshTableSetComponent,
    YshOrderStatusCellComponent,
    YshPageHeaderComponent,
    YshAttributesComponent,
  ],
})
export class VehicleComponent implements OnInit {

  @Input() uid: string;

  dataSource: TableDataSouce<Order>;
  vehicle?: Vehicle;
  vehicleAttributes: AttributesListItem[];

  constructor(private orderService: OrderService, private vehicleService: VehicleService, private router: Router) {}

  ngOnInit(): void {
    this.dataSource = {
      title: "",
      columns: [
        { field: "timeCompletedString", title: 'Time Completed'},
        { field: "addressString", title: 'Address', cellSize: 'large'},
        { field: "serviceString", title: 'service'},
        { field: "status", title: 'status', component: YshOrderStatusCellComponent}
      ],
      load: (state) => this.orderService.getManyForVehicle(this.uid, state),
      displayRowNav: true,
      filters: [],
      actions: [],
    }
    this.getVehicle();
  }

  getVehicle() {
    this.vehicleService.get(this.uid).subscribe(vehicle => {
      this.vehicle = vehicle;
      this.vehicleAttributes = [
        { label: 'Year/Make/Model', value: this.vehicle?.description },
        { label: 'Color', value: this.vehicle?.color },
        { label: 'License', value: this.vehicle?.license },
        { label: 'VIN', value: this.vehicle?.vin },
        { label: 'Serial Number', value: this.vehicle?.serialNumber },
        { label: 'Last Fuel', value: this.vehicle?.lastGasRefillCompletedAt },
        { label: 'Last Wash', value: this.vehicle?.lastCarWashCompletedAt },
        { label: 'Last Oil Change', value: this.vehicle?.lastOilChangeCompletedAt },
      ]
    });
  }

  didTapItem(item: Order){
    this.router.navigate([AppRoutes.Orders, item.uid]);
  }
}
