<button
  class="button"
  [ngClass]="['size-' + size, 'theme-' + theme]"
  [disabled]="disabled || loading"
>
  @if (loading) {
    <ysh-dots size="medium" color="blue"/>
  }
  @else {
    @if (icon) {
      <mat-icon class="button__icon">{{icon}}</mat-icon>
    }
    @if (label) {
      <span class="button__label">{{label}}</span>
    }
  }
</button>
