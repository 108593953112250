import { Component, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { YshButtonControlComponent, YshButtonControlType } from "../ysh-button-control/ysh-button-control.component";

@Component({
  selector: 'ysh-split-layout',
  templateUrl: './ysh-split-layout.component.html',
  styleUrl: './ysh-split-layout.component.scss',
  standalone: true,
  imports: [
    MatIconModule,
    MatTooltipModule,
    YshButtonControlComponent,
  ],
})
export class YshSplitLayoutComponent {
  @Input({ required: true }) theme: 'blue' | 'space' | 'smoke';
  @Input() hasLogo?: boolean;
  @Input() onNavigate?: () => void;
  @Input() buttonControlType?: YshButtonControlType = 'back';
  @Input() srcImage?: string;
  @Input() containImage?: boolean;
  @Input() reverseImage?: boolean;
  @Input() reverseLayout?: boolean;
  @Input() hideVisualForMobile?: boolean;

  get srcForLogo(): string {
    return `/assets/logos/yoshi-logo-${this.theme === 'smoke' ? 'space' : 'white'}.svg`;
  }

  get srcForImage(): string {
    return this.srcImage || `/assets/images/split-layout-theme-${this.theme}.svg`;
  }
}
