import { CanActivate, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { AppRoutes } from '../../../app/app.routes';
import { UserService } from '../user.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  loggedIn = false;

  constructor(private router: Router, private userService: UserService) {
    this.userService.loggedIn$.subscribe(loggedIn => {
      this.loggedIn = loggedIn;
    });
  }

  canActivate(): boolean {
    if (!this.loggedIn) {
      this.router.navigate([AppRoutes.Login]);
    }
    return this.loggedIn;
  }
}
