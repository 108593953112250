import { Component, Input, OnInit } from '@angular/core';
import { ComponentWithInputs, TableDataSouce, TableState, YshTableSetComponent } from '../../components/ysh-table-set/ysh-table-set.component';
import { Router } from '@angular/router';
import { AppRoutes, companyRoute } from '../../app.routes';
import { DeliveryService } from '../../services/api/delivery/delivery.service';
import { Delivery } from '../../models/delivery';
import { YshBadgeComponent } from '../../components/ysh-badge/ysh-badge.component';
import { YshBadgeActionComponent, } from '../../components/ysh-badge-action/ysh-badge-action.component';
import { DialogService } from '../../services/ui/dialog/dialog.service';
import { CompanyService } from '../../services/api/company/company.service';
import { first, mergeMap, Observable } from 'rxjs';


@Component({
  selector: 'app-appointments',
  templateUrl: './appointments.component.html',
  styleUrl: './appointments.component.scss',
  standalone: true,
  imports: [
    YshTableSetComponent,
  ],
})
export class AppointmentsComponent implements OnInit {
  
  dataSource: TableDataSouce<Delivery>;

  @Input() companyUid?: string;

  constructor(
    private companyService: CompanyService,
    private deliveryService: DeliveryService, 
    private dialogService: DialogService, 
    private router: Router){}

  ngOnInit(): void {
    this.loadData();
  }


  loadData() {
    this.dataSource = {
      title: "Appointments",
      columns: [
        { field: "dateString", title: 'Date' },
        { field: "timeWindowString", title: 'Time' },
        { field: "userString", title: 'Team Member' },
        { field: "vehicleCount", title: 'Vehicles', cellSize: 'small'},
        { field: "serviceDescriptionString", title: 'Services'},
        { field: "status", title: 'status', cellSize: 'small', component: YshBadgeComponent },
        {
          field: "uid", title: 'Action', cellSize: 'small',
          componentWithInputs: (item) => new ComponentWithInputs(YshBadgeActionComponent, {
            theme: 'coral',
            value: 'Cancel',
            disabled: item.status === "complete",
            onTap: () => this.didTapCancel(item)
          })
        }
      ],
      load: (state) => this.getDeliveries(state),
      displayRowNav: true,
      filters: [],
      actions: [
        {
          buttonLabel: 'New Appointment',
          buttonTheme: 'blue',
          onTap: () => this.didTapNew(),
        },
      ],
    }
  }

  getDeliveries(state: TableState){
    return this.companyService.get(this.companyUid).pipe(mergeMap(company => {
      return this.deliveryService.getMany(state, company?.accountUid)
    }))
  }

  // Actions

  didTapNew(){
    this.companyService.currentCompany$.pipe(first()).subscribe((company)=>{
      const route = companyRoute(AppRoutes.OrderFlow, company);
      this.router.navigateByUrl(route);
    });
  }

  didTapItem(item: Delivery) {
    this.router.navigate([AppRoutes.Appointments, item.uid]);
  }

  didTapCancel(item: Delivery) {
    this.dialogService.showDialog({
      heading: 'Confirm Cancel!',
      caption: 'Are you sure you want to cancel this appointment?',
      buttonLabelConfirm: 'Yes, Confirm',
      buttonThemeConfirm: 'coral',
      buttonLabelCancel: 'Not now',
      onConfirm: () => {
        this.deliveryService.cancel(item.uid).subscribe(()=>{
          this.loadData();
        });
      }
    });
  }
}
