import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, ValidationErrors, ValidatorFn } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { FormatFieldService } from '../../services/format-field.service';
import { YshButtonComponent } from "../ysh-button/ysh-button.component";
import { debounceTime, distinctUntilChanged, Subject, takeUntil } from 'rxjs';
import { RouterModule } from '@angular/router';

export interface FormField {
  name: string;
  label: string;
  type: string;
  /** Name from Material Icons */
  icon?: string;
  /** Half width field */
  half?: boolean;
  /** Group name for related fields */
  group?: string;
  readonly?: boolean;
  /** Field hint text shown on focus  */
  hint?: string;
  onTap?: (param?: any) => void;
  validators?: ValidatorFn[];
}

export interface FormLink {
  label: string;
  route: string;
  queryParamsHandling?: 'merge' | 'preserve';
}

@Component({
  selector: 'ysh-form',
  templateUrl: './ysh-form.component.html',
  styleUrls: ['./ysh-form.component.scss'],
  standalone: true,
  imports: [
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    YshButtonComponent,
    RouterModule,
  ],
})
export class YshFormComponent implements OnInit, OnDestroy {
  @Input() formHeading = 'Form';
  @Input() formCaption?: string;
  @Input() formSubmitLabel = 'Submit';
  @Input() formErrorMessage = 'Error. Please try again.';
  @Input() formLinks: FormLink[] = [];
  @Input() formFields: FormField[] = [];
  @Input() formFieldsAddLimit?: number;
  @Input() onAddField?: () => void;
  @Input() onSubmit: (formValues: any) => Promise<void>;

  formGroup: FormGroup;
  formFieldsCountLoaded: number;
  formErrorMessages: string[] = [];
  formSubmitting = false;
  fieldErrorMessages = {};

  private unsubscribe: Subject<void> = new Subject();

  constructor(
    private formBuilder: FormBuilder,
    private formatFieldService: FormatFieldService,
  ) {}

  ngOnInit(): void {
    this.formBuild();
    this.formFieldsCountLoaded = this.formFieldsCount;
  }

  formBuild(preserveValues: boolean = false) {
    const existingValues = preserveValues ? this.formGroup?.value || {} : {};
    this.formGroup = this.formBuilder.group(
      Object.fromEntries(
        this.formFields.map(({ name, validators = [] }) => [name, [existingValues[name] || '', validators]])
      )
    );
    this.formGroup.statusChanges.subscribe(() => this.formGroup.markAsPristine());
    this.formatFieldService.phoneField(this.formGroup, this.formFields);
    this.listenForErrors();
  }
  
  markFieldDirty(field: string) {
    this.formGroup.get(field)?.markAsDirty();
    this.fieldErrorMessages = this.findErrors(this.formGroup);
  }

  listenForErrors(){
    this.formGroup.valueChanges.pipe(
      debounceTime(100),
      distinctUntilChanged(),
      takeUntil(this.unsubscribe)).subscribe(()=>{
        if (this.formGroup.invalid) {
          this.fieldErrorMessages = this.findErrors(this.formGroup)
        }
    });
  }

  private findErrors(formGroup: FormGroup){
    const errorMessages = {};
    for (let control in formGroup.controls) {
      const errors = formGroup.get(control).errors;
      if (errors) {
        errorMessages[control] = this.messageForErrors(errors);
      }
    }
    return errorMessages;
  }

  private messageForErrors(errors: ValidationErrors): string{
    if (errors.serverError) {
      return errors.serverError;
    } else if (errors.required) {
      return 'Required field';
    } else if (errors.minlength) {
      return `Max length is ${errors.minlength.actualLength}/${errors.minlength.requiredLength}`;
    } else if (errors.maxlength) {
      return `Min length is ${errors.maxlength.actualLength}/${errors.maxlength.requiredLength}`;
    } else if (errors.email) {
      return errors.email;
    } else if (errors.min) {
      return `Min value is ${errors.min.min}, actual value is ${errors.min.actual}`;
    } else if (errors.max) {
      return `Max value is ${errors.max.max}, actual value is ${errors.max.actual}`;
    } else if (errors.pattern) {
      return 'Invalid value';
    } else if (errors.passwordMismatch) {
      return 'Passwords do not match';
    } else if (errors.match) {
      return errors.match
    } else if (errors.password) {
      return errors.password
    } else if (errors.phone) {
      return errors.phone;
    } else if (errors.duplicateValue) {
      return errors.duplicateValue;
    } else {
      return;
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  private get formFieldsCount(): number {
    const groupFields = new Set(this.formFields.map(field => field.group).filter(Boolean)).size;
    const singleFields = this.formFields.filter(field => !field.group).length;
    return singleFields + groupFields;
  }  
  
  get canAddField(): boolean {
    return this.formFieldsCount < this.formFieldsCountLoaded + this.formFieldsAddLimit;
  }
  
  get canRemoveField(): boolean {
    return this.formFieldsCount > 1;
  }
  
  handleAddField() {
    this.onAddField?.();
    this.formBuild(true);
  }

  handleRemoveField() {
    const initialCount = this.formFieldsCount;
    while (this.formFieldsCount > initialCount - 1) {
      this.formFields.pop();
    }
    this.formBuild(true);
  }

  handleTapField(event: MouseEvent, formField: FormField) {
    if (formField?.onTap) {
      event.preventDefault();
      event.stopPropagation();
      formField.onTap();
    }
  }  

  async handleSubmit() {
    this.formSubmitting = true;
    try {
      await this.onSubmit(this.formGroup.value);
    } catch (err: any) {
      this.formErrorMessages = this.getFormErrorMessages(err) || [this.formErrorMessage];
    } finally {
      this.formSubmitting = false;
    }
  }

  private getFormErrorMessages(err) {
    return err?.error?.errors?.map(e => e.message || e);
  }
}
