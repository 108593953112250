import { Component, OnInit } from '@angular/core';
import { TableDataSouce, TableFilter, YshTableSetComponent } from '../../components/ysh-table-set/ysh-table-set.component';
import { VehicleService } from '../../services/vehicle/vehicle.service';
import { Vehicle } from '../../models/vehicle';
import { YshOrderStatusCellComponent } from '../schedule/components/ysh-order-status-cell/ysh-order-status-cell.component';
import { Router } from '@angular/router';
import { AppRoutes } from '../../app.routes';

@Component({
  selector: 'app-vehicles',
  templateUrl: './vehicles.component.html',
  styleUrl: './vehicles.component.scss',
  standalone: true,
  imports: [
    YshTableSetComponent,
    YshOrderStatusCellComponent
  ],
})
export class VehiclesComponent implements OnInit {

  dataSource: TableDataSouce<Vehicle>;

  constructor(private vehicleService: VehicleService, private router: Router) {}

  ngOnInit(): void {
    this.dataSource = {
      title: "Vehicles",
      columns: [
        { field: "year", title: 'year', sortable: true, cellSize: 'small'},
        { field: "make", title: 'make', sortable: true},
        { field: "model", title: 'model', sortable: true, cellSize: 'large'},
        { field: "vin", title: 'vin', sortable: true, cellSize: 'large'},
        { field: "serialNumber", title: 'serial no', sortable: true},
        { field: "license", title: 'license', sortable: true},
        { field: "lastGasRefillCompletedAt", title: 'Last Gas Refill', sortable: true},
        { field: "lastCarWashCompletedAt", title: 'Last Wash', sortable: true},
        { field: "lastOilChangeCompletedAt", title: 'Last Oil', sortable: true}
      ],
      filters:[TableFilter.Search],
      actions: [],
      displayRowNav: true,
      load: (state) => this.vehicleService.getMany(state)
    }
  }
  
  didTapItem(item: Vehicle) {
    this.router.navigate([AppRoutes.Vehicles, item.uid]);
  }
}
