import { Component, OnInit } from '@angular/core';
import { User } from '../../models/user';
import { YshPageHeaderComponent } from "../../components/ysh-page-header/ysh-page-header.component";
import { UserService } from '../../services/api/user/user.service';
import { YshPanelComponent } from '../../components/ysh-panel/ysh-panel.component';
import { Trait, YshTraitsComponent } from '../../components/ysh-traits/ysh-traits.component';
import { MatIconModule } from '@angular/material/icon';
import { CreditCardService } from '../../services/api/credit-card/credit-card.service';
import { ModalService } from '../../services/ui/modal/modal.service';
import { AddCardPage } from '../../flows/add-card/add-card.page';

@Component({
  selector: 'app-my-account',
  templateUrl: './my-account.component.html',
  styleUrl: './my-account.component.scss',
  standalone: true,
  imports: [YshPageHeaderComponent, YshPanelComponent, YshTraitsComponent, MatIconModule],
})
export class MyAccountComponent implements OnInit {
  user: User;
  userInfo?: Trait[];
  userCompanies?: Trait[];
  userCreditCardInfo?: Trait[];

  constructor(
    public userService: UserService,
    private creditCardService: CreditCardService,
    private modalService: ModalService,
  ){}
  
  ngOnInit() {
    this.getUserInfo();
    this.getUserCompanies();
    this.getUserCreditCardInfo();
  }

  getUserInfo() {
    this.userService.currentUser$.subscribe(user => {
      this.user = user;
      this.userInfo = [
        { label: 'Name', value: this.user?.name },
        { label: 'Phone Number', value: this.user?.phoneFormatted },
        { label: 'Email Address', value: this.user?.email },
        { label: 'Account Created', value: this.user?.createdAtFormatted },
      ];
    });
  }

  getUserCompanies() {
    this.userService.currentUser$.subscribe(user => {
      this.userCompanies = user?.affiliations?.map(affiliation => ({
        label: affiliation.company.name,
        value: affiliation.roleFormatted,
      })) || [];
    });
  }

  getUserCreditCardInfo() {
    this.creditCardService.getMany({}).subscribe(cards => {
      const card = cards.items[0];
      this.userCreditCardInfo = card && [
        { label: 'Card Number', value: '•••• •••• •••• ' + card?.last4 },
        { label: 'Card Type', value: card?.brand },
      ]
    })
  }

  handleAddCreditCard() {
    this.modalService.present({
      component: AddCardPage,
      componentProps: {
        onComplete: () => {
          this.getUserCreditCardInfo();
        } 
      } 
    });
  }
}
