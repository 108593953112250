import { Component, OnInit } from '@angular/core';
import { TableDataSouce, YshTableSetComponent } from '../../components/ysh-table-set/ysh-table-set.component';
import { Order } from '../../models/order';
import { OrderService } from '../../services/order/order.services';
import { YshOrderStatusCellComponent } from './components/ysh-order-status-cell/ysh-order-status-cell.component';

@Component({
  selector: 'app-schedule',
  templateUrl: './schedule.component.html',
  styleUrl: './schedule.component.scss',
  standalone: true,
  imports: [
    YshTableSetComponent,
  ],
})
export class ScheduleComponent implements OnInit {
  
  dataSource: TableDataSouce<Order>;

  constructor(private orderService: OrderService){}

  ngOnInit(): void {
    this.dataSource = {
      title: "Service Schedule",
      columns: [
        { field: "dateString", title: 'Date'},
        { field: "shiftTimeString", title: 'Time', cellSize: 'small'},
        { field: "addressString", title: 'Address', cellSize: 'large'},
        { field: "serviceString", title: 'service'},
        { field: "status", title: 'status', component: YshOrderStatusCellComponent},
      ],
      load: (state) => this.orderService.getMany(state),
      filters: [],
      actions: [],
    }
  }
}
