
import { Injectable } from '@angular/core';
import { ApiService, GetManyParams } from '../../api.service';
import { Company } from '../../../models/company';
import { Affilation, CreateAffiliationParams } from '../../../models/affiliation';

@Injectable({
  providedIn: 'root',
})
export class AffiliationService {
  constructor(private apiService: ApiService) {}

  get(uid: string){
    const url = `/affiliations/${uid}`
    return this.apiService.get(url, Affilation);
  }

  getMany(params: GetManyParams, companyUid) {
    const url = `/companies/${companyUid}/affiliations`;
    return this.apiService.getMany(url, {...params}, Affilation);
  }

  create(companyUid: string, params: CreateAffiliationParams){
    const url = `/companies/${companyUid}/affiliations`
    return this.apiService.post(url, Affilation, params);
  }

  createBulk(companyUid: string, params: CreateAffiliationParams[]){
    const url = `/companies/${companyUid}/affiliations/bulk`
    return this.apiService.postBulk(url, Affilation, { referredUsers: params });
  }
}
