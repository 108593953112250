import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { Validators } from '@angular/forms';
import { delay, take, firstValueFrom, map, Observable } from 'rxjs';
import { UserService } from '../../services/api/user/user.service';
import { FormField, FormLink, YshFormComponent } from '../../components/ysh-form/ysh-form.component';
import { YshSplitLayoutComponent } from "../../components/ysh-split-layout/ysh-split-layout.component";
import { Router, ActivatedRoute } from '@angular/router';
import { AppRoutes, companyRoute } from '../../app.routes';
import { AnalyticsService } from '../../services/analytics/analytics.service';
import { MapGeocoder } from '@angular/google-maps';
import generator from 'generate-password-ts'
import { AddressParams, addressParamsForGooglePlaceData } from '../../models/user';
import { checkPasswordValidator, HINT_PASSWORD } from '../../components/ysh-form/validators/password.validator';
import { MatIconModule } from '@angular/material/icon';
import { AffiliationService } from '../../services/api/affiliation/affiliation.service';
import { Affilation } from '../../models/affiliation';
import { CompanyService } from '../../services/api/company/company.service';
import { phoneValidator } from '../../components/ysh-form/validators/phone.validator';
import { emailValidator } from '../../components/ysh-form/validators/email.validator';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrl: './signup.component.scss',
  standalone: true,
  imports: [YshFormComponent, YshSplitLayoutComponent, MatIconModule],
})
export class SignupComponent implements OnInit {
  @ViewChild(YshFormComponent) form!: YshFormComponent;

  isOrderMode: boolean = false;
  isInviteMode: boolean = false;
  affiliationUid?: string;
  companyName?: string = '';
  affiliation?: Affilation;
  formFieldsReady: boolean = false;

  formFields: FormField[] = [
    { 
      name: 'firstName',
      label: 'First Name',
      type: 'text',
      half: true,
      validators: [Validators.required] 
    },
    { 
      name: 'lastName',
      label: 'Last Name',
      type: 'text',
      half: true,
      validators: [Validators.required] 
    },
    { 
      name: 'companyName',
      label: 'Company Name',
      type: 'text',
      icon: 'people_alt',
      hint: 'Company or your name for inspection form',
      validators: [Validators.required]
    },
    {
      name: 'email',
      label: 'Email Address',
      type: 'email',
      icon: 'alternate_email',
      validators: [Validators.required, emailValidator()]
    },
    { 
      name: 'phone',
      label: 'Phone Number',
      type: 'tel',
      icon: 'phone',
      half: true,
      validators: [Validators.required, phoneValidator()]
    },
    {
      name: 'zipCode',
      label: 'Zip Code',
      type: 'number',
      icon: 'place',
      half: true,
      validators: [Validators.required, Validators.pattern('^[0-9]{5}$')]
    },
    {
      name: 'password',
      label: 'Password',
      type: 'password',
      icon: 'lock',
      hint: HINT_PASSWORD,
      validators: [Validators.required, checkPasswordValidator()]
    }
  ];

  formLinks: FormLink[] = [
    {
      route: AppRoutes.Login,
      label: 'Have an Account? Sign in.',
      queryParamsHandling: 'preserve',
    },
  ]

  constructor(
    private analytics: AnalyticsService,
    private companyService: CompanyService,
    private geocoder: MapGeocoder,
    private router: Router,
    private userService: UserService,
    private activatedRoute: ActivatedRoute,
    private affiliationService: AffiliationService,
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.analytics.trackView('SignupComponent');
    this.setSignupMode();
  }
  
  setSignupMode() {
    this.activatedRoute.queryParams.subscribe(params => {
      this.companyName = params['company'] ?? '';
      this.isOrderMode = !params['affiliation_uid'] && params['order'] === 'true';
      this.affiliationUid = params['affiliation_uid'];
  
      if (this.affiliationUid) {
        this.affiliationService.get(this.affiliationUid).subscribe(affiliation => {
          this.affiliation = affiliation;
          this.isInviteMode = true;
          this.companyName = this.affiliation.company?.name;
          this.excludeFormFields(['zipCode', 'companyName']);
          this.formFieldsReady = true;
          this.setFormFieldValue('email', this.affiliation.userEmail, true);
        });
      } else {
        this.excludeFormFields(['password']);
        this.formFieldsReady = true;
      }
    });
  }
  
  setFormFieldValue(field: string, value: string | number, readonly?: boolean) {
    this.cdr.detectChanges();
    this.form?.formGroup.get(field)?.setValue(value);
    this.formFields.find(f => f.name === field)!.readonly = readonly;
  }
  
  excludeFormFields(fields: string[]) {
    if (fields?.length) {
      this.formFields = this.formFields.filter(field => !fields.includes(field.name));
      fields.forEach(field => this.form?.formGroup.removeControl(field));
    }
  }

  get formHeading() {
    if (this.isOrderMode) {
      return `<em>Appointments are available!</em> Add your info to see scheduling options.`;
    }
    if (this.isInviteMode) {
      return `Finish creating your account to join <em>${this.companyName}</em>`;
    }
    return 'Create Account';
  }

  async handleSignup(formValues: any): Promise<void> {
    const password = this.isInviteMode ? formValues.password : this.generatePassword();
    let addressParams: AddressParams = {};
    try {  
      if (!this.isInviteMode && formValues.zipCode) {
        addressParams = await firstValueFrom(this.geocodeZipCode(formValues.zipCode));
        if (!addressParams.zipCode) {
          throw "Please use a valid zip code.";
        }
      }
      const params = {
        firstName: formValues.firstName,
        lastName: formValues.lastName,
        companyName: formValues.companyName,
        phone: formValues.phone,
        email: formValues.email,
        password: password,
        ...addressParams
      }
      const request = this.affiliation
        ? this.userService.claim(this.affiliation.user.uid, params)
        : this.userService.signup(params);

      await firstValueFrom(request.pipe(delay(500)));
      await firstValueFrom(
        this.userService.login(formValues.email, password)
      );
      this.analytics.trackSignup();
      this.handleSignupComplete();
    } catch (error) {
      console.error(error);
      throw error; // Throw error for YshFormComponent to handle
    }
  }

  handleSignupComplete() {
    if (this.isOrderMode) {
      this.companyService.currentCompany$.pipe(take(2)).subscribe(company => {
        this.router.navigateByUrl(
          companyRoute(AppRoutes.OrderFlow, company),
          { replaceUrl: true }
        );
      });
    } else {
      this.router.navigate([AppRoutes.Welcome], { 
        state: {
          canNavigate: true,
          hasOrderButton: true,
        } 
      });
    }
  }

  geocodeZipCode(zipCode: string): Observable<AddressParams> {
    return this.geocoder.geocode({ address: String(zipCode) }).pipe(
      map((response) => {
        if (response.status !== google.maps.GeocoderStatus.OK) {
          throw new Error('Invalid Zip: ' + response.status);
        }
        if (!response.status[0]) {
          throw new Error('Invalid Zip: Not found');
        }
        const params = addressParamsForGooglePlaceData(response.results[0] as any);
        return params;
      })
    );
  }

  private generatePassword(){
    return generator.generate({
      length: 16,
      numbers: true,
      symbols: '%',
      uppercase: true,
      lowercase: true,
      strict: true
    });
  }
}
