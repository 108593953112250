import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export type ToastType = 'info' | 'success' | 'warning' | 'error';

export interface Toast {
  type: ToastType;
  message: string;
  duration: number;
}

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  private toastSubject = new BehaviorSubject<Toast>(null);
  toast$ = this.toastSubject.asObservable();

  show(type: ToastType, message: string, duration: number = 3000): void {
    this.toastSubject.next({ type, message, duration });
    setTimeout(() => this.hide(), duration);
  }

  hide(): void {
    this.toastSubject.next(null);
  }
}
