import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AppRoutes } from '../../app.routes';

// required by HttpRequest#clone({ setHeaders: Headers })
interface Headers {
  [name: string]: string;
}

export interface AuthData extends Headers {
  'access-token': string;
  client: string;
  expiry: string;
  'token-type': string;
  uid: string;
  user_uid: string;
}

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  static readonly AUTH_HEADERS = ['access-token', 'client', 'expiry', 'token-type', 'uid', 'user_uid'];
  static readonly USER_UID = 'USER_UID'

  headers?: AuthData;

  private authDataSource$ = new BehaviorSubject<AuthData>(null);
  authData$ = this.authDataSource$.pipe(shareReplay(1));

  constructor(private router: Router) {
    this.load();
  }

  private isAuthData(data: any): data is AuthData {
    return AuthService.AUTH_HEADERS.every(
      field => field in data && typeof data[field] === 'string',
    );
  }

  private valid(data: AuthData) {
    return Date.now() / 1000 <= +data.expiry;
  }

  load() {
    const data = {};
    AuthService.AUTH_HEADERS.forEach(header => {
      data[header] = localStorage.getItem(header);
    });
    if (this.isAuthData(data) && this.valid(data)) {
      this.headers = data;
      this.authDataSource$.next(data);
    } else {
      this.destroy();
    }
  }

  save(headers: HttpHeaders) {
    AuthService.AUTH_HEADERS.forEach(header => {
      localStorage.setItem(header, headers.get(header));
    });
    this.load();
  }

  destroy() {
    this.headers = null;
    AuthService.AUTH_HEADERS.forEach(header => {
      localStorage.removeItem(header);
    });
    this.authDataSource$.next(null);
  }

  logout(refresh?: boolean) {
    this.destroy();
    localStorage.clear();
    sessionStorage.clear();

    if (refresh) {
      window.location.href = AppRoutes.Login;
    } else {
      this.router.navigateByUrl(AppRoutes.Login);
    }
  }
}
