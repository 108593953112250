import { Component, Input, OnInit } from '@angular/core';
import { BaseChartDirective } from 'ng2-charts';
import { ChartType, ChartData, ChartOptions, defaults, TooltipItem } from 'chart.js';

export interface ChartProps {
  type: ChartType;
  data: ChartData;
}

@Component({
  selector: 'ysh-chart',
  templateUrl: './ysh-chart.component.html',
  styleUrl: './ysh-chart.component.scss',
  standalone: true,
  imports: [BaseChartDirective],
})
export class YshChartComponent implements OnInit {

  @Input() type: ChartProps['type'];
  @Input() data: ChartProps['data'];

  chartOptions: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    color: defaults.color = 'rgb(85,105,135)',
    backgroundColor: defaults.backgroundColor = 'rgba(0,100,255)', // fallback for setDefaultColors()
    borderColor: defaults.borderColor = 'rgba(15,35,65,0.1)',
    font: {
      family: defaults.font.family = 'YoshiSans',
      weight: defaults.font.weight = 300,
    },
    layout: {
      padding: {
        top: 24,
        bottom: -8, // offset extra space from legend padding
      }
    },
    elements: {
      line: {
        borderWidth: 2, // line chart border width
      },
      point: {
        radius: 4, // line chart point size
        hoverRadius: 3,
        borderWidth: 0,
      },
      arc: {
        borderWidth: 0, // no border for pie & doughnut charts
      }
    },
    plugins: {
      legend: {
        align: 'start',
        position: 'bottom',
        labels: {
          usePointStyle: true, // use circle shape
          boxHeight: 6,
          boxWidth: 6,
        }
      },
      tooltip: {
        backgroundColor: 'rgba(15,35,65,0.9)',
        callbacks: {
          label: this.setTooltipLabel,
        }
      }
    }
  }

  ngOnInit(): void {
    this.setDefaultColors();
  }

  setDefaultColors() {
    const colors = ['#0064FF','#00B4FF','#2D415F','#00D2A0','#556987','#FF6E5F'];
    const isCircleChart = ['pie','doughnut'].includes(this.type);
    const isLineChart = ['line'].includes(this.type);

    this.data?.datasets.forEach((dataset, index) => {
      if (!dataset.backgroundColor) {
        if (isCircleChart) {
          dataset.backgroundColor = dataset.data.map((_,dataIndex) => colors[dataIndex % colors.length]);
        } else {
          dataset.backgroundColor = colors[index % colors.length];
        }
      }
      if (!dataset.borderColor && isLineChart) {
        dataset.borderColor = colors[index % colors.length];
      }
    });
  }

  setTooltipLabel(tooltipItem: TooltipItem<any>) {
    const value = tooltipItem.raw as number;
    const isCurrency = false; // TODO: update this
    if (isCurrency) {
      return new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'}).format(value);
    } else {
      return value.toString();
    }
  }
}
