
import { Component, Input, OnInit } from '@angular/core';
import { FlowPage } from '../flow-director';
import { AnalyticsService } from '../../services/analytics/analytics.service';
import { YshFlowContentComponent } from "../../components/ysh-flow-content/ysh-flow-content.component";
import { YshFlowNavBarComponent } from "../../components/ysh-flow-nav-bar/ysh-flow-nav-bar.component";
import { YshButtonControlComponent } from "../../components/ysh-button-control/ysh-button-control.component";
import { GroupItem, YshSelectList } from "../../components/ysh-select-list/ysh-select-list.component";

@Component({
  selector: 'ysh-select-role',
  templateUrl: './select-role.page.html',
  styleUrl: './select-role.page.scss',
  standalone: true,
  imports: [
    YshFlowContentComponent,
    YshFlowNavBarComponent,
    YshButtonControlComponent,
    YshSelectList,
  ],
})
export class SelectRolePage implements OnInit, FlowPage {
  @Input() onComplete: (values: string[]) => void;
  @Input() onDismiss?: () => void;
  @Input() initialSelection: string[] = [];

  constructor(
    private analytics: AnalyticsService,
  ) {}

  roleSelection: string[] = [];

  roleItems: GroupItem[] = [
    {
      title: null,
      items: [
        { value: 'Admin', label: 'Full Portal Access' },
        { value: 'Driver', label: 'Limited Portal Access' },
      ],
    },
  ];

  ngOnInit(): void {
    this.analytics.trackView('SelectRolePage');
    this.roleSelection = [...this.initialSelection];
  }

  // Actions

  handleSelectionChange(values: string[]) {
    this.roleSelection = values;
    this.onComplete?.(values);
  }

  handleDismiss() {
    this.onDismiss?.();
  }  
}
