import { Injectable } from "@angular/core";
import { map, Observable } from "rxjs";
import { Company } from "../../models/company";
import { Affilation } from "../../models/affiliation";
import { BaseEntity } from "../../models/base";
import { CreditCard } from "../../models/credit-card";
import { Dashboard } from "../../models/dashboard";
import { Delivery } from "../../models/delivery";
import { Invoice } from "../../models/invoice";
import { Vehicle } from "../../models/vehicle";
import { ApiService } from "../api.service";
import { AuthService } from "../auth/auth.service";
import { Order } from "../../models/order";

export enum Action {
  Post = 'create',
  Delete = 'destroy',
  Get = 'show',
  GetMany = 'index',
  Patch = 'update',
}

export enum Resource {
  Affiliation = 'affiliation',
  CreditCard = 'credit_card',
  Dashboard = 'dashboard',
  Delivery = 'delivery',
  Invoice = 'invoice',
  Order = 'order',
  Vehicle = 'vehicle'
}

type PermissionMap = {[key in Action]?: boolean };

export class Permission {
  constructor(private _permission: PermissionMap){}
  canAccess(action: Action){
    return Boolean(this._permission[action]);
  } 
}

type ResourcePermissionMap = { [key in Resource]?: PermissionMap };

export class ResourcePermissions {
  private _resourcePermissions: { [key in Resource]?: Permission } = {}
  constructor(permissionMap: ResourcePermissionMap){
    Object.keys(permissionMap).forEach(key => {
      this._resourcePermissions[key] = new Permission(permissionMap[key]);
    });
  }
  canAccess(action: Action, resource: Resource){
    return Boolean(this._resourcePermissions?.[resource]?.canAccess(action));
  }
}

@Injectable({
  providedIn: 'root',
})
export class PermissionService {

  static readonly DEFAULT_RESOURCES = [Resource.Affiliation, Resource.CreditCard, Resource.Dashboard, Resource.Delivery, Resource.Invoice, Resource.Vehicle];

  constructor(private api: ApiService){}
  
  getPermissions(company: Company, resources = PermissionService.DEFAULT_RESOURCES): Observable<ResourcePermissions>{
    const params = {
      resources: resources.map(resource=>(JSON.stringify({class_name: resource}))),
      companyUid: company?.uid
    };
    const url = `/users/${AuthService.USER_UID}/permissions`
    return this.api.get(url, null, params).pipe(map((data: any)=>new ResourcePermissions(data)));
  }

  getPermissionForObject(object: BaseEntity) {
    const resource = this.resourceForObject(object);
    const url = `/users/${AuthService.USER_UID}/permissions`
    const params = { 
      resource: [JSON.stringify({class_name:resource, uid: object.uid})] 
    }
    return this.api.get(url, null, params).pipe(map((response)=>response as ResourcePermissionMap));
  }

  private resourceForModel(entity: typeof BaseEntity) {
    const modelResourceMap = new Map<string, string>([
      [Affilation.classId, Resource.Affiliation],
      [CreditCard.classId, Resource.CreditCard],
      [Dashboard.classId, Resource.Dashboard],
      [Delivery.classId, Resource.Delivery],
      [Invoice.classId, Resource.Invoice],
      [Order.classId, Resource.Order],
      [Vehicle.classId, Resource.Vehicle],
    ]);
    return modelResourceMap[entity.classId];
  }
  
  private resourceForObject(object: BaseEntity): Resource {
    const entity = (object.constructor as typeof BaseEntity)
    return this.resourceForModel(entity);
  }
}
