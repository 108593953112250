@if (imageSrc) {
  <img class="traits-image" [src]="imageSrc">
}
<div class="traits">
  @if (heading) {
    <h5 class="heading">{{heading}}</h5>
  }
  @for (trait of traits; track trait) {
    <div class="trait">
      <div class="trait__label" [class]="'opacity-' + labelOpacity">{{trait.label}}</div>
      <div class="trait__value">
        @if (trait.linkHref) {
          <a class="trait__value__link" [class]="getLinkStyle(trait)" [class.reverse]="trait.linkIconReverse" [href]="trait.linkHref" [target]="getLinkTarget(trait)">
            @if (trait.linkIcon) {
              <mat-icon size-small>{{trait.linkIcon}}</mat-icon>
            }
            {{trait.value}}
          </a>
        }
        @else if (trait.badgeTheme) {
          <ysh-badge [value]="trait.value" [theme]="trait.badgeTheme"/>
        }
        @else {
          {{trait.value}}
        }
      </div>
    </div>
  }
</div>
