import { Injectable } from '@angular/core';
import { ApiService } from '../../api.service';
import { Company } from '../../../models/company';
import { BehaviorSubject, Observable, of, shareReplay } from 'rxjs';
import { UserService } from '../user/user.service';

@Injectable({
  providedIn: 'root',
})
export class CompanyService {
  private _selectedCompany$ = new BehaviorSubject<Company | null>(this.getStoredCompany());
  currentCompany$: Observable<Company | null> = this._selectedCompany$.pipe(shareReplay(1));;

  constructor(private apiService: ApiService, private userService: UserService) {
    this.userService.currentUser$.subscribe(user => {
      if (!this._selectedCompany$.value && user?.companies?.length) {
        this.setSelectedCompany(user.companies[0]);
      }
    });
  }

  setSelectedCompany(company?: Company) {
    this._selectedCompany$.next(company);

    if (company) {
      localStorage.setItem('selectedCompany', JSON.stringify(company));
    } else {
      localStorage.removeItem('selectedCompany');
    }
  }

  private getStoredCompany(): Company | null {
    const storedCompany = localStorage.getItem('selectedCompany');
    return storedCompany ? JSON.parse(storedCompany) : null;
  }

  get(uid?: string): Observable<Company | null> {
    const url = `/companies/${uid}`;
    return uid ? this.apiService.get(url, Company) : of(null);
  }
}
