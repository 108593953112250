import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppRoutes, companyRoute } from '../../app.routes';
import { AnalyticsService } from '../../services/analytics/analytics.service';
import { YshSplitLayoutComponent } from "../../components/ysh-split-layout/ysh-split-layout.component";
import { YshButtonComponent } from "../../components/ysh-button/ysh-button.component";
import { User } from '../../models/user';
import { UserService } from '../../services/api/user/user.service';
import { CompanyService } from '../../services/api/company/company.service';
import { first, take } from 'rxjs';

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrl: './welcome.component.scss',
  standalone: true,
  imports: [YshSplitLayoutComponent, YshButtonComponent],
})
export class WelcomeComponent implements OnInit {
  @Input() heading?: string;
  @Input() message?: string;

  hasOrderButton: boolean = false;

  constructor(
    private analytics: AnalyticsService,
    private router: Router,
    private companyService: CompanyService
  ) {}
  
  ngOnInit(): void {
    this.analytics.trackView('WelcomeComponent');
    this.hasOrderButton = history.state?.hasOrderButton;
  }

  private navigateTo(route: AppRoutes) {
    this.companyService.currentCompany$.pipe(first()).subscribe(company => {
      this.router.navigateByUrl(companyRoute(route, company));
    });
  }
  
  navigateToDashboard() {
    this.navigateTo(AppRoutes.Dashboard);
  }
  
  navigateToOrder() {
    this.navigateTo(AppRoutes.OrderFlow);
  }
   
  get headingText() {
    return this.heading ?? `<em>Success!</em> Welcome to your inspection and vehicle management portal.`;
  }
  
  get messageText() {
    return this.message ?? 'You can add vehicles, schedule inspection appointments, manage assets and vehicle data, plus more. As a next step, continue to the dashboard or start an order.';
  }
}
