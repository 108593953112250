import { Component, Input, OnInit } from '@angular/core';
import { ComponentWithInputs, TableDataSouce, TableFilter, TableState, YshTableSetComponent } from '../../components/ysh-table-set/ysh-table-set.component';
import { InvoiceService } from '../../services/api/invoice/invoice.service';
import { Invoice } from '../../models/invoice';
import { YshBadgeActionComponent } from '../../components/ysh-badge-action/ysh-badge-action.component';
import { YshBadgeComponent } from '../../components/ysh-badge/ysh-badge.component';
import { CompanyService } from '../../services/api/company/company.service';
import { mergeMap } from 'rxjs';

@Component({
  selector: 'app-invoices',
  templateUrl: './invoices.component.html',
  styleUrl: './invoices.component.scss',
  standalone: true,
  imports: [
    YshTableSetComponent,
  ],
})
export class InvoicesComponent implements OnInit{

  @Input() companyUid?: string;

  dataSource: TableDataSouce<Invoice>;

  constructor(private companyService: CompanyService, private invoiceService: InvoiceService){}

  ngOnInit(): void {
    this.dataSource = {
      title: "Invoices",
      columns: [
        { field: "startDate", title: 'Start Date', sortable: true },
        { field: "endDate", title: 'End Date', sortable: true },
        { field: "amountString", title: 'amount', sortable: true },
        {
          title: 'status',
          componentWithInputs: (item) => new ComponentWithInputs(YshBadgeComponent, {
            value: item.statusString
          })
        },
      ],
      load: (state) => this.loadData(state),
      filters: [TableFilter.StartEndDate],
      actions: [],
    }
  }

  private loadData(state: TableState){
    return this.companyService.get(this.companyUid).pipe(mergeMap(company => {
      return this.invoiceService.getMany(state, company?.accountUid)
    }));
  }
}
