@if (text) {
  <div class="text">{{text}}</div>
}
@if (imageSrc) {
  <img class="image" src={{imageSrc}}>
}
@if (videoSrc) {
  <video class="video" src={{videoSrc}} controls></video>
}
@if (iframeSrc) {
  <iframe class="iframe" [src]="iframeSrcSafe"></iframe>
}
  