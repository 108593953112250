import { BaseEntity, MemoizeGetters } from './base';

@MemoizeGetters
export class Address extends BaseEntity {
  locationName: string;
  lat: number;
  lng: number;
  uid: string;

  get details(): LocationDetails {
    return parseLocationName(this.locationName);
  }
}

export interface LocationDetails {
  street?: string;
  city?: string;
  state?: string;
  country?: string;
  nickname?: string;
  description?: string;
}

export function parseLocationName(locationName: string): LocationDetails {
  locationName = locationName || '';
  const location: LocationDetails = {};
  const parts = locationName.split(',').reverse();
  parts.forEach((part, index) => {
    part = part.replace(/^\s+|\s+$/g, '');
    if (part === 'USA' || part === 'United States') {
      location.country = part;
    } else if (index === 1) {
      location.state = part;
    } else if (index === 2) {
      location.city = part;
    } else if (index === 3) {
      location.street = part;
    } else if (index === 4) {
      location.nickname = part;
    } else if (parts.length === 1) {
      location.nickname = part;
    }
  });
  const streetCity = [location.street, location.city].filter((n) => n).join(' ');
  const cityState = [location.city, location.state].filter((n) => n).join(', ');
  location.description = location.nickname || location.street ? streetCity : cityState;
  return location;
}