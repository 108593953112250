<div class="my-account-page">
  <ysh-page-header title="My Account"/>
  <div class="panels">
    <ysh-panel
      class="panel"
      heading="Personal Info"
      padding="left-right-bottom">

      <ysh-traits [traits]="userInfo" imageSrc="assets/images/avatar-default-blue.png"/>
    </ysh-panel>
    
    @if (userCompanies?.length) {
      <ysh-panel
        class="panel"
        heading="Companies"
        infotip="Company affiliations & roles"
        padding="left-right-bottom">
        
        <ysh-traits [traits]="userCompanies" labelOpacity="full"/>
      </ysh-panel>
    } @else {
      <ysh-panel
        class="panel"
        heading="Payment"
        [actionLabel]="userCreditCardInfo ? 'Change' : 'Add Card'"
        (onAction)="handleAddCreditCard()"
        padding="left-right-bottom">
    
        @if(userCreditCardInfo) {
          <ysh-traits [traits]="userCreditCardInfo"/>
        }
      </ysh-panel>
    }
  </div>
</div>
