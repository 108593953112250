import moment from 'moment';
import { Address } from './address';
import { BaseEntity, MemoizeGetters, toDate, toEntity, Transform } from './base';
import { TimeWindow } from './timeWindow';
import { Service } from './service';
import { ServiceType } from './service_type';
import { Vehicle } from './vehicle';
import { Photo } from './photos';

export enum OrderStatus {
  Complete = 'complete',
  Pending = 'pending',
}

export const OrderIconsMap: Record<OrderStatus, string> = {
  [OrderStatus.Complete]: './assets/images/order-complete.svg',
  [OrderStatus.Pending]: './assets/images/order-pending.svg',
};

export interface ShiftStartEnd {
  shiftStartTime?: TimeWindow;
  shiftEndTime?: TimeWindow;
}

@MemoizeGetters
export class Order extends BaseEntity {
  eta: TimeWindow;
  numGallonsPumped: number;
  shiftStartTime: TimeWindow;
  shiftEndTime: TimeWindow;
  status: OrderStatus;

  @Transform(toEntity(Address))
  address: Address;

  @Transform(toDate)
  date: Date;

  @Transform(toEntity(Service))
  service: Service;

  @Transform(toEntity(ServiceType))
  serviceType: ServiceType;

  @Transform(toDate)
  timeCompleted: TimeWindow;

  @Transform(toEntity(Vehicle))
  vehicle: Vehicle;

  @Transform(toEntity(Photo))
  photos: Photo[];


  get addressString() {
    return this.address.details.street;
  }

  get dateString() {
    return moment(this.date).format('MMM Do YYYY');
  }

  get description() {
    return `${this.service.name} - ${this.vehicle.description}`;
  }

  get serviceString() {
    return this.serviceType.title;
  }

  get shiftTimeString() {
    return createShiftWindowString(this, false);
  }

  get timeCompletedString() {
    return moment(this.timeCompleted).format('MMM Do YYYY h:mm a');
  }

}

export const createShiftWindowString = (obj: ShiftStartEnd, minutes = false) => {
  const format = minutes ? 'h:mma' : 'ha';
  const start = obj.shiftStartTime ? moment(obj.shiftStartTime).format(format) : '';
  const end = obj.shiftEndTime ? moment(obj.shiftEndTime).format(format) : '';
  return start && end ? `${start} - ${end}` : '';
};