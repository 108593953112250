@if (formSubmitted) {
  <ysh-split-layout theme="blue" [containImage]="true" srcImage="/assets/images/graphic-order-complete.svg">
    <div class="confirmation">
      <h2 class="confirmation__heading"><em>Success!</em> Invitations have been sent to your coworkers.</h2>
      <p class="confirmation__message">Invitation emails have been sent to your coworkers, with instructions to finish creating their account. You can now head back to the company overview page.</p>
      <ysh-button block theme="blue" label="Back to Company" (click)="handleBack()"/>
    </div>
  </ysh-split-layout>
}
@else {
  <ysh-split-layout theme="smoke" [onNavigate]="handleBack">
    <ysh-form
      formHeading="Invite Team Members"
      formSubmitLabel="Send Invites"
      formErrorMessage=""
      [formFields]="formFields"
      [formFieldsAddLimit]="7"
      [onAddField]="handleAddFieldGroup"
      [onSubmit]="handleSubmitInvite.bind(this)"
    />
  </ysh-split-layout>
}
