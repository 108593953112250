import { Component, Input, OnInit } from '@angular/core';
import { TableAction, TableDataSouce, YshTableSetComponent } from '../../components/ysh-table-set/ysh-table-set.component';
import { ReportService } from '../../services/report.service';
import { Report } from '../../models/report';
import { FileFormat } from '../../services/api.service';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrl: './reports.component.scss',
  standalone: true,
  imports: [
    YshTableSetComponent,
  ],
})
export class ReportComponent implements OnInit {

  @Input() name: string;

  dataSource: TableDataSouce<any>;
  report?: Report;

  constructor(private reportService: ReportService) {}

  ngOnInit(): void {
    this.getReport();
  }

  getReport(): void {
    this.reportService.get(this.name).subscribe((report  => {
      this.report = report;
      this.dataSource = {
        title: this.report.title,
        load: (state) => this.reportService.getData(this.name, state),
        filters: [],
        actions: [TableAction.DownloadCSV],
        columns: this.report.columns
      };
    }));
  }

  didTapAction(action: TableAction): void {
    this.reportService.download(this.name, FileFormat.CSV);
  }
}
