import { Routes, mapToCanActivate } from '@angular/router';
import { AuthGuard } from './services/guards/auth.guard';
import { SignupComponent } from './pages/signup/signup.component';
import { LoginComponent } from './pages/login/login.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { InvoicesComponent } from './pages/invoices/invoices.component';
import { VehiclesComponent } from './pages/vehicles/vehicles.component';
import { ScheduleComponent } from './pages/schedule/schedule.component';
import { ReportComponent } from './pages/reports/reports.component';
import { VehicleComponent } from './pages/vehicle/vehicle.component';
import { OrderComponent } from './pages/order/order.component';
import { AccountComponent } from './pages/account/account.component';
import { ErrorComponent } from './pages/error/error.component';

export enum AppRoutes {
  Signup = 'signup',
  Login = 'login',
  Dashboard = '',
  Account = 'account',
  Invoices = 'invoices',
  Vehicles = 'vehicles',
  Vehicle = 'vehicles/:uid',
  Orders = 'orders',
  Order = 'orders/:uid',
  Schedule = 'schedule',
  Unauthorized = 'unauthorized',
  Report = 'reports/:name',
  Error = '**',
}

export const routes: Routes = [
  { path: AppRoutes.Signup,
    component: SignupComponent,
    canActivate: mapToCanActivate([AuthGuard])
  },
  { path: AppRoutes.Login,
    component: LoginComponent,
    canActivate: mapToCanActivate([AuthGuard])
  },
  { path: AppRoutes.Dashboard,
    component: DashboardComponent,
    canActivate: mapToCanActivate([AuthGuard]) 
  },
  { path: AppRoutes.Account,
    component: AccountComponent,
    canActivate: mapToCanActivate([AuthGuard]) 
  },
  { path: AppRoutes.Invoices,
    component: InvoicesComponent,
    canActivate: mapToCanActivate([AuthGuard]) 
  },
  { path: AppRoutes.Vehicles,
    component: VehiclesComponent,
    canActivate: mapToCanActivate([AuthGuard]) 
  },
  { path: AppRoutes.Vehicle,
    component: VehicleComponent,
    canActivate: mapToCanActivate([AuthGuard]),
  },
  { path: AppRoutes.Schedule,
    component: ScheduleComponent,
    canActivate: mapToCanActivate([AuthGuard]) 
  },
  { path: AppRoutes.Report,
    component: ReportComponent,
    canActivate: mapToCanActivate([AuthGuard]) 
  },
  { path: AppRoutes.Order,
    component: OrderComponent,
    canActivate: mapToCanActivate([AuthGuard]) 
  },

  // KEEP ERROR LAST (angular applies routes in order & uses first match in array)
  { path: AppRoutes.Error,
    component: ErrorComponent
  },
];
