import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, Output, Type, ViewChild } from '@angular/core';
import { MatSort, MatSortModule, Sort } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatPaginator, MatPaginatorModule, PageEvent } from '@angular/material/paginator';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatTooltipModule } from '@angular/material/tooltip';
import { provideNativeDateAdapter } from '@angular/material/core';
import { debounceTime, Observable, Subject } from 'rxjs';
import { CommonModule } from '@angular/common';
import { PageInfo } from '../../services/api.service';
import { YshDotsComponent } from "../ysh-dots/ysh-dots.component";
import { YshButtonTheme, YshButtonComponent } from '../ysh-button/ysh-button.component';

export interface ColumnDetail<T> {
  /**
   * Column id + attrubute of entity to display. If empty, entity will be passed
   */
  field?: keyof T & string,

  /**
   * Field with icon (name from Material Icons)
   */
  fieldIcon?: string,
  
  /**
   * Function when tapping field
   */
  fieldTap?: (item: T) => void,

  /**
   * Column header title
   */
  title?: string,

  /**
   * Column supports sorting
   */
  sortable?: boolean,

  /**
   * Sorting paramater to use for API Call
   */
  sortParam?: string,

  /**
   * Cell size for smaller/larger data
   */
  cellSize?: 'small' | 'large',

  /**
   * Optional component to use for display instead of plain value
   */

  component?: Type<Component & WithValue<T>> ,

  /**
   * Optional factory to provide a component along with properties
   */
  componentWithInputs?: (item: T) => ComponentWithInputs<unknown>
}

export type WithValue<T> = { value: T } | { value : string};

export class ComponentWithInputs<T> {
  constructor(public component: Type<T>, public inputs: Partial<T>) {} 
}

export interface TableState {
  sortBy?: string;
  sortOrder?: string;
  page?: number;
  filter?: string;
  dateStartInclusive?: string;
  dateEndInclusive?: string;
}

export enum TableFilter {
  Search = 'search',
  StartEndDate = 'startEndDate'
}

export interface TableAction {
  buttonLabel: string;
  buttonIcon?: string;
  buttonTheme?: YshButtonTheme;
  onTap: () => void;
}

export interface TableDataSouce<T> {
  title: string;
  columns: ColumnDetail<T>[];
  displayRowNav?: boolean;
  filters: TableFilter[];
  actions: TableAction[];
  noDataMessage?: string;
  load?: (state: TableState) => Observable<{ items: T[], paginationInfo?: PageInfo, }>;
}

@Component({
  selector: 'ysh-table-set',
  templateUrl: './ysh-table-set.component.html',
  styleUrl: './ysh-table-set.component.scss',
  standalone: true,
  providers: [provideNativeDateAdapter()],
  hostDirectives: [MatSort],
  imports: [
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatPaginatorModule,
    MatTableModule,
    MatSortModule,
    MatDatepickerModule,
    MatTooltipModule,
    YshDotsComponent,
    YshButtonComponent,
    CommonModule
  ],
})
export class YshTableSetComponent<T> implements AfterViewInit {

  readonly DEBOUNCE_TIME = 500;

  _dataSource: TableDataSouce<T>
  _loading = true;
  _fields: string[] = [];
  _items: T[] = [];
  _paginationInfo: PageInfo;
  _hasSearchFilter = false;
  _hasStartEndDateFilter = false;
  _hasHeader = false;

  @Input() set dataSource(dataSource: TableDataSouce<any>) {
    this._dataSource = dataSource;
    if (dataSource){
      const fields = dataSource.columns.map(item => item.field || item.title);
      const navField =  dataSource.displayRowNav ? ["navigation"] : [];
      this._fields = [...fields, ...navField];
      this._hasSearchFilter = dataSource.filters.includes(TableFilter.Search);
      this._hasStartEndDateFilter = dataSource.filters.includes(TableFilter.StartEndDate);
      this._hasHeader = [dataSource.filters, dataSource.actions, dataSource.title].some(a => a.length);
      this.loadData(this.tableState);
    }
  }

  @Output() onItemTap: EventEmitter<T> = new EventEmitter();

  @ViewChild(MatPaginator) paginator: MatPaginator;

  private searchSubject = new Subject<string>;
  private tableState: TableState = { page: 1 };
  filter: string;
  filtering: boolean;

  constructor(private cdRef: ChangeDetectorRef){}

  ngAfterViewInit() {
    this.searchSubject.
      pipe(debounceTime(this.DEBOUNCE_TIME)).
      subscribe(value => {
        this.filtering = false;
        this.tableState.filter = value;
        this.tableState.page = 1;
        this.paginator.pageIndex = 0;
        this.loadData(this.tableState);
      });
  }

  didChangeFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.filtering = true;
    this.filter = filterValue;
    this.searchSubject.next(filterValue?.length ? filterValue : undefined);
  }

  didChangeDate(startDate: string, endDate: string){
    this.tableState.dateStartInclusive = startDate;
    this.tableState.dateEndInclusive = endDate;
    this.loadData(this.tableState);
  }

  didChangePage(page: PageEvent) {
    this.tableState.page = page.pageIndex + 1;
    this.loadData(this.tableState);
  }

  didChangeSort(sort: Sort) {
    this.tableState.sortBy = sort.active;
    this.tableState.sortOrder = sort.direction;
    this.loadData(this.tableState);
  }

  didTapItem(item: T){
    this.onItemTap.emit(item);
  }

  private loadData(state: TableState) {
    this._dataSource.load(state)?.subscribe((data)=>{
      this._items = data.items;
      this._paginationInfo = data.paginationInfo;
      this._loading = false;
    })
  }
}
