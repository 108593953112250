import { CommonModule } from '@angular/common';
import { Component, HostBinding, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';

export type YshButtonControlType = 'back' | 'next' | 'exit';
export type YshButtonControlSize = 'small' | 'medium' | 'large';
export type YshButtonControlTheme = 'white' | 'smoke' | 'shadow';
export type YshButtonControlPosition = 'top-left' | 'top-right';

@Component({
  selector: 'ysh-button-control',
  templateUrl: './ysh-button-control.component.html',
  styleUrl: './ysh-button-control.component.scss',
  standalone: true,
  imports: [CommonModule, MatIconModule, MatTooltipModule],
})
export class YshButtonControlComponent {
  @Input() type: YshButtonControlType = 'back';
  @Input() size: YshButtonControlSize = 'large';
  @Input() theme: YshButtonControlTheme = 'smoke';
  @Input() position: YshButtonControlPosition = null;
  @Input() hasTooltip: boolean = true;

  @HostBinding('class') get hostClass(): string {
    return this.position && `position-${this.position}`;
  }

  get icon() {
    return {
      back: 'arrow_back',
      next: 'arrow_forward',
      exit: 'close'
    }[this.type];
  }

  get tooltip() {
    return {
      back: 'Go Back',
      next: 'Go Next',
      exit: 'Exit'
    }[this.type];
  }

  get tooltipPosition() {
    return this.position === 'top-left' ? 'right' : 'left';
  }
}
