<ysh-split-layout theme="blue" [containImage]="true" srcImage="/assets/images/graphic-order-complete.svg">
  <div class="welcome">
    <h2 class="welcome__heading" [innerHTML]="headingText"></h2>
    <p class="welcome__message" [innerHTML]="messageText"></p>
    <div class="welcome__buttons"> 
      <ysh-button block [theme]="hasOrderButton ? 'smoke' : 'blue'" label="Go to Dashboard" (click)="navigateToDashboard()"/>
      @if (hasOrderButton) {
        <ysh-button block theme="blue" label="Order Inspection" (click)="navigateToOrder()"/>
      }
    </div>
  </div>
</ysh-split-layout>
