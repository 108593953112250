import { Component, Input, OnInit } from '@angular/core';
import { TableDataSouce, TableFilter, TableState, YshTableSetComponent } from '../../components/ysh-table-set/ysh-table-set.component';
import { VehicleService } from '../../services/api/vehicle/vehicle.service';
import { Vehicle } from '../../models/vehicle';
import { Router } from '@angular/router';
import { AppRoutes } from '../../app.routes';
import { CompanyService } from '../../services/api/company/company.service';
import { mergeMap } from 'rxjs';

@Component({
  selector: 'app-vehicles',
  templateUrl: './vehicles.component.html',
  styleUrl: './vehicles.component.scss',
  standalone: true,
  imports: [
    YshTableSetComponent,
  ],
})
export class VehiclesComponent implements OnInit {

  @Input() companyUid?: string;

  dataSource: TableDataSouce<Vehicle>;

  constructor(
    private companyService: CompanyService, 
    private vehicleService: VehicleService, 
    private router: Router) {}

  ngOnInit(): void {
    this.dataSource = {
      title: "Vehicles",
      columns: [
        { field: "year", title: 'year', sortable: true, cellSize: 'small'},
        { field: "make", title: 'make', sortable: true},
        { field: "model", title: 'model', sortable: true, cellSize: 'large'},
        { field: "vin", title: 'vin', sortable: true, cellSize: 'large'},
        { field: "serialNumber", title: 'serial no', sortable: true},
        { field: "license", title: 'license', sortable: true},
        { field: "lastInspectionCompletedAt", title: 'Last Inspection', sortable: true},
      ],
      filters:[TableFilter.Search],
      actions: [],
      displayRowNav: true,
      noDataMessage: "Your vehicles will be listed here after they are first inspected.",
      load: (state) => this.loadData(state)
    }
  }

  private loadData(state: TableState){
    return this.companyService.get(this.companyUid).pipe(mergeMap(company => {
      return this.vehicleService.getMany(state, company?.accountUid)
    }));
  }
  
  didTapItem(item: Vehicle) {
    this.router.navigate([AppRoutes.Vehicles, item.uid]);
  }
}
