import { BaseEntity, MemoizeGetters, Transform, toDate } from "./base";


@MemoizeGetters
export class Vehicle extends BaseEntity {
  color: string;
  license: string;
  nickname: string;
  serialNumber: string;
  make: string;
  model: string;
  vin: string;
  year: string;
  serial: string;

  @Transform(toDate)
  lastGasRefillCompletedAt: Date;

  @Transform(toDate)
  lastOilChangeCompletedAt: Date;

  @Transform(toDate)
  lastCarWashCompletedAt: Date;

  get description(){
    return `${this.year} ${this.make} ${this.model}`.trim();
  }
  
}
