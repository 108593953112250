<ysh-split-layout theme="space" [hasLogo]="true" [onNavigate]="!token && handleBack">
  @if(!token){
    @if (submitted) {
      <div class="confirmation">
        <img class="confirmation__graphic" src="/assets/images/graphic-password-reset-sent.png">
        <h2 class="confirmation__heading">Check Your Email</h2>
        <p class="confirmation__message">We sent instructions to create or reset your password. If you haven’t received the email, please check your spam folder.</p>
      </div>
    } @else { 
      <ysh-form
      formHeading="Create or Reset Password"
      formCaption="Enter the email associated with your account and we’ll send you instructions to create or reset your password."
      formSubmitLabel="Send Instructions"
      [formFields]="emailFormFields"
      [onSubmit]="onEmailSubmit.bind(this)"
      ></ysh-form>
    }
  } @else { 
    @if (submitted) {
      <div class="confirmation">
        <img class="confirmation__graphic" src="/assets/images/graphic-password-reset-done.png">
        <h2 class="confirmation__heading">Password Updated</h2>
        <p class="confirmation__message">We have successfully updated your account. You may now login with your new password.</p>
        <ysh-button class="confirmation__button" label="Login" (click)="didTapLogin()"></ysh-button>
      </div>
    } @else { 
      <ysh-form
      formHeading="Choose New Password"
      formSubmitLabel="Save Password"
      [formFields]="passwordFormFields"
      [onSubmit]="onPasswordSubmit.bind(this)"
      ></ysh-form>
    }
  }
</ysh-split-layout>
