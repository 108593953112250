<div class="order-page">
  @if (order) {
    <ysh-page-header
      title={{order.description}}
      backLinkRoute="{{orderVehicleRoute}}"
      backLinkLabel="Vehicle Details"
    />
    <ysh-attributes
      [listItems]="orderAttributes"
    />
    @if (orderPhotos.length) {
      <h3>Service Photos</h3>
      <br>
      <ysh-gallery
        [images]="orderPhotos"
      />
    }
  }
</div>
