import { ApiService, GetManyParams } from '../api.service';
import { Injectable } from '@angular/core';
import { Order, OrderStatus } from '../../models/order';
import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root',
})
export class OrderService {
  constructor(private apiService: ApiService) {}

  get(uid: string) {
    const url = `/orders/${uid}`;
    return this.apiService.get(url, Order);
  }

  getMany(params: GetManyParams, status = OrderStatus.Pending) {
    const url = `/users/${AuthService.USER_UID}/orders`;
    return this.apiService.getMany(url, { ...params, status }, Order);
  }

  getManyForVehicle(uid, params: GetManyParams, status = OrderStatus.Complete) {
    const url = `/vehicles/${uid}/orders`;
    return this.apiService.getMany(url, { ...params, status }, Order);
  }
}
