import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { YshButtonComponent } from '../ysh-button/ysh-button.component';

@Component({
  selector: 'ysh-modal',
  templateUrl: './ysh-modal.component.html',
  styleUrl: './ysh-modal.component.scss',
  standalone: true,
  imports: [MatIconModule, MatTooltipModule, YshButtonComponent],
})
export class YshModalComponent {
  @Input() isVisible: boolean = false;
  @Output() onClose = new EventEmitter();

  didTapClose() {
    this.onClose.emit();
  }
}
