import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class YoshiSupportService {
  emailAddress = 'mailto:support@yoshimobility.com';
  emailTemplate = `${this.emailAddress}?subject=SUBJECT&body=BODY`;

  constructor() {}

  getLoginSupportEmail() {
    return this.updateEmailTemplate(
      'Login help needed!',
      'I need help logging into my corporate dashboard.',
    );
  }

  updateEmailTemplate(emailSubject: string, emailBody: string) {
    let emailTemplate = this.emailTemplate;
    emailTemplate = emailTemplate.replace('SUBJECT', emailSubject).replace('BODY', emailBody);
    return emailTemplate;
  }
}
