import { BaseEntity, Default, MemoizeGetters } from './base';

enum ChargeType {
  QuickBooks = 'quickbooks_charging',
  Stripe = 'stripe_charging',
  Manual = 'manual_charging',
}

enum ChargeStatus {
  NotStarted = 'charge_not_started',
  Succeed = 'charge_succeeded',
  Failed = 'charge_failed',
  ReadyToCharge = 'ready_to_charge',
  InProgress = 'charge_in_progress',
  MissingPayment = 'missing_payment_method',
}

enum InvoiceStatus {
  Paid = 'Paid',
  Overdue = 'Overdue',
  DueToday = 'Due Today',
  DueIn = 'Due in',
}

enum PaymentStatus {
  Open = 'open',
  PartiallyPaid = 'partially_paid',
  Paid = 'paid'
}

@MemoizeGetters
export class Invoice extends BaseEntity {

  amount: number;
  chargeStatus: ChargeStatus;

  @Default(PaymentStatus.Open)
  sagePaymentStatus: PaymentStatus;

  @Default("https://www.google.com")
  sagePaymentLink?: string;

  chargeType: ChargeType;
  startDate: string;
  endDate: string;
  status: InvoiceStatus;
  pdfDownloadLink: string;
  csvDownloadLink: string;
  invoiceNumber: string;

  get amountString(){
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    });
    return formatter.format(this.amount); 
  }
}

