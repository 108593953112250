import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { UserService } from '../../services/user.service';
import { YoshiSupportService } from '../../services/yoshi-support.service';
import { finalize } from 'rxjs/operators';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    MatProgressSpinnerModule,
  ],
})
export class LoginComponent implements OnInit {
  readonly LOGIN_ERROR_MESSAGE = 'Invalid email or password. Please retry.';
  LOGIN_SUPPORT_EMAIL: string;

  loginForm: FormGroup;
  loggingIn = false;
  loginError: Error | undefined;

  constructor(
    private userService: UserService,
    private router: Router,
    private formBuilder: FormBuilder,
    private yoshiSupport: YoshiSupportService,
  ) {
    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]],
    },{
      updateOn: 'submit',
    });

    this.LOGIN_SUPPORT_EMAIL = this.yoshiSupport.getLoginSupportEmail();
  }

  ngOnInit() {
    this.userService.loggedIn$.subscribe(loggedIn => {
      if (loggedIn) {
        this.router.navigate(['']);
      }
    });
  }

  // Actions

  didTapLogin() {
    const email = this.loginForm.controls.email.value;
    const password = this.loginForm.controls.password.value;
    this.loggingIn = true;
    this.userService
      .login(email, password)
      .pipe(finalize(() => (this.loggingIn = false)))
      .subscribe({
        complete: () => {},
        error: error => this.loginError = error,
     });
  }
}
