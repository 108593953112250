import { CommonModule } from '@angular/common';
import { Component, HostListener, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

export interface CarouselItem {
  src: string;
  caption?: string,
}

@Component({
  selector: 'ysh-carousel',
  templateUrl: './ysh-carousel.component.html',
  styleUrl: './ysh-carousel.component.scss',
  standalone: true,
  imports: [CommonModule, MatIconModule],
})
export class YshCarouselComponent {
  @Input() items: CarouselItem[] = [];
  @Input() activeItem: number = 0;
  
  @HostListener('window:keyup', ['$event'])
  onKeyup(event: KeyboardEvent) {
    event.key === 'ArrowRight' && this.showNextItem();
    event.key === 'ArrowLeft' && this.showPrevItem();
  }
  
  showNextItem() {
    this.activeItem = (this.activeItem + 1) % this.items.length;
  }
  
  showPrevItem() {
    this.activeItem = (this.activeItem - 1 + this.items.length) % this.items.length;
  }
}
