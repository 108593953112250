<div class="select-list">
  <h2 class="heading">{{heading}}</h2>
  @if (isSearchable) {
    <mat-form-field class="search" size-small>
      <input matInput placeholder="Search">
      <mat-icon size-medium matPrefix>search</mat-icon>
    </mat-form-field>
  }
  @for (group of groupItems; track group) {
    <div class="group">
      @if (group.title) {
        <h3 class="group__title">{{group.title}}</h3>
      }
      @for (item of group.items; track item) {
        <div class="group__item" (click)="handleSelectItem(item)">
          <div class="group__item__info">
            <div class="group__item__info__value">{{item.value}}</div>
            <div class="group__item__info__label">{{item.label}}</div>
          </div>
          <div class="group__item__selector" [class.selected]="item.selected">
            <mat-icon>{{ item.selected ? 'check' : 'add' }}</mat-icon>
          </div>
        </div>
      }
    </div>
  }
</div>
