import { CanActivate, Router, ActivatedRouteSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { AppRoutes } from '../../../app/app.routes';
import { UserService } from '../user/user.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {

  constructor(private router: Router, private userService: UserService) {}

  canActivate(route: ActivatedRouteSnapshot): boolean {
    const isLoginOrSignup = [
      AppRoutes.Login,
      AppRoutes.Signup
    ].includes(route.routeConfig?.path as AppRoutes);

    this.userService.loggedIn$.subscribe(loggedIn => {
      if (loggedIn && isLoginOrSignup) {
        this.router.navigate(['']);
      } else if (!loggedIn && !isLoginOrSignup) {
        this.router.navigate([AppRoutes.Login]);
      }
    });
    return true;
  }
}
