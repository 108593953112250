import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';

// TODO: better name for Item?
export interface Item {
  value: string;
  label?: string;
  selected?: boolean;
}
export interface GroupItem {
  title?: string;
  items: Item[];
}

@Component({
  selector: 'ysh-select-list',
  templateUrl: './ysh-select-list.component.html',
  styleUrl: './ysh-select-list.component.scss',
  standalone: true,
  imports: [
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
  ],
})
export class YshSelectList implements OnInit {
  @Input() heading: string = 'Select Items';
  @Input() groupItems: GroupItem[] = [];
  @Input() isMultiSelect: boolean = false;
  @Input() isSearchable: boolean = false;
  @Input() selection: string[] = [];
  @Input() selectionPreserve: boolean = false;
  @Output() selectionChange = new EventEmitter();

  ngOnInit(): void {
    if (this.selectionPreserve) {
      this.groupItems.flatMap(group => group.items).forEach(item => {
        item.selected = this.selection.includes(item.value);
      });
    }
  }

  handleSelectItem(item: Item) {
    const items = this.groupItems.flatMap(group => group.items);
    item.selected = !item.selected;
    if (!this.isMultiSelect) {
      items.forEach(i => i !== item && (i.selected = false));
    }
    this.selectionChange.emit(items.filter(i => i.selected).map(i => i.value));
  }
}
