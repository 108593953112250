import { Component, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

export interface AttributesListItem {
  label: string,
  value: string | number | Date,
}

@Component({
  selector: 'ysh-attributes',
  templateUrl: './ysh-attributes.component.html',
  styleUrl: './ysh-attributes.component.scss',
  standalone: true,
  imports: [MatIconModule, MatProgressSpinnerModule],
})
export class YshAttributesComponent {
  @Input() listItems: AttributesListItem[] = [];
  @Input() imageSrc?: string;
}
