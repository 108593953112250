<button
  class="button"
  [class]="'theme-' + theme"
  [class.control]="isControl"
  [disabled]="disabled || spinner"
>
  @if (spinner) {
    <mat-spinner diameter="20"/>
  }
  @else {
    @if (icon) {
      <mat-icon class="button__icon">{{icon}}</mat-icon>
    }
    @if (label && !isControl) {
      <span class="button__label">{{label}}</span>
    }
  }
</button>
