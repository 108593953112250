import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';

export interface GridTile {
  heading: string,
  subheading: string,
  infotip?: string;
  component: any,
  componentInput?: any;
  gridArea?: string,
  aspectRatio?: string,
}

@Component({
  selector: 'ysh-grid',
  templateUrl: './ysh-grid.component.html',
  styleUrl: './ysh-grid.component.scss',
  standalone: true,
  imports: [CommonModule, MatIconModule, MatTooltipModule],
})
export class YshGridComponent {

  @Input() tiles: GridTile[] = [];
  @Input() numberOfColumns: number = 3;
  @Input() numberOfRows: number = 3;
}
