import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function duplicateValueValidator(formFields: any[], fieldType: string): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value?.toLowerCase();
    const matchingFields = formFields.filter(field => field.name.startsWith(fieldType));
    const fieldValues = matchingFields.map(field => control?.parent?.get(field.name)?.value?.toLowerCase());
    const duplicate = fieldValues.filter(v => v === value).length > 1;
    return duplicate ? { duplicateValue: 'Enter a unique value' } : null;
  };
}
