import { ApiService, GetManyParams } from '../../api.service';
import { Injectable } from '@angular/core';
import { Invoice } from '../../../models/invoice';
import { AuthService } from '../../auth/auth.service';

@Injectable({
  providedIn: 'root',
})
export class InvoiceService {
  constructor(private apiService: ApiService) {}

  getMany(params: GetManyParams, accountUid?: string) {
    const url = `/users/${accountUid || AuthService.USER_UID}/invoices`;
    return this.apiService.getMany(url, { ...params }, Invoice);
  }
}

interface InvoiceParams {
  date_start_inclusive?: string;
  date_end_inclusive?: string;
  page: number;
}
