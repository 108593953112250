<div class="carousel">
  @for (item of items; track item; let i = $index) {
    <div class="carousel__item" [class.active]="i===activeItem">
      <img class="carousel__item__image" [src]="item.src">
      @if (item.caption) {
        <div class="carousel__item__caption">
          <mat-icon>info</mat-icon> {{item.caption}}
        </div>
      }
    </div>    
  }
  @if (items.length > 1) {
    <div class="carousel__controls">
      <button class="carousel__controls__button" (click)="showPrevItem()">
        <mat-icon>chevron_left</mat-icon>
      </button>
      <div class="carousel__controls__counter">
        {{activeItem + 1 }}/{{ items.length }}
      </div>
      <button class="carousel__controls__button" (click)="showNextItem()">
        <mat-icon>chevron_right</mat-icon>
      </button>
    </div>
  }
</div>
