import { CanActivate, Router, ActivatedRouteSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { AppRoutes, RouteData } from '../../../app/app.routes';
import { UserService } from '../api/user/user.service';


@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {

  constructor(private router: Router, private userService: UserService) {}

  canActivate(route: ActivatedRouteSnapshot): boolean {
    const { hasNavigationAccessOnly, hasLoggedOutAccessOnly } = route.data as RouteData;
    const canNavigate = this.router.getCurrentNavigation()?.extras.state?.canNavigate;
  
    if (hasNavigationAccessOnly && !canNavigate) {
      this.router.navigate(['']);
      return false;
    }
    this.userService.loggedIn$.subscribe(loggedIn => {
      if (loggedIn && hasLoggedOutAccessOnly) {
        this.router.navigate(['']);
      } else if (!loggedIn && !hasLoggedOutAccessOnly) {
        this.router.navigate([AppRoutes.Login]);
      }
    });
    return true;
  }
}
