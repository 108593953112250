<div class="inspection-page">
  <ysh-page-header
    title="Inspection Details"
    [backLinkRoute]="backLinkRoute"
    backLinkLabel="All Inspections"
  />
  <div class="grid">
    <ysh-panel
      class="grid__panel"
      heading="Overview"
      padding="left-right-bottom">

      @if (orderOverview) {
        <ysh-traits [traits]="orderOverview"/>
      }
    </ysh-panel>

    <ysh-panel
      class="grid__panel"
      heading="Results"
      infotip="Results: OK or Needs Repair"
      padding="left-right-bottom">

      @if (orderDetails) {
        <ysh-traits [traits]="orderDetails" heading="Inspection Point" labelOpacity="full"/>
      }
    </ysh-panel>

    <ysh-panel
      class="grid__panel"
      heading="Images"
      infotip="Tap image for details"
      padding="all-sides">

      @if (orderPhotos) {
        <ysh-gallery [images]="orderPhotos"/>
      }
    </ysh-panel>
  </div>
</div>
