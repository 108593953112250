import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { FlowPage } from '../flow-director';
import { AnalyticsService } from '../../services/analytics/analytics.service';
import { YshSplitLayoutComponent } from "../../components/ysh-split-layout/ysh-split-layout.component";
import { FormField, YshFormComponent } from "../../components/ysh-form/ysh-form.component";
import { Validators } from '@angular/forms';
import { ModalService } from '../../services/ui/modal/modal.service';
import { SelectRolePage } from '../select-role/select-role.page';
import { duplicateValueValidator } from '../../components/ysh-form/validators/duplicate-value.validator';
import { CreateAffiliationParams } from '../../models/affiliation';
import { AffiliationService } from '../../services/api/affiliation/affiliation.service';
import { Company } from '../../models/company';
import { firstValueFrom } from 'rxjs';
import { YshButtonComponent } from "../../components/ysh-button/ysh-button.component";
import { emailValidator } from '../../components/ysh-form/validators/email.validator';

@Component({
  selector: 'ysh-invite',
  templateUrl: './invite.page.html',
  styleUrl: './invite.page.scss',
  standalone: true,
  imports: [
    YshSplitLayoutComponent,
    YshFormComponent,
    YshButtonComponent
  ],
})
export class InvitePage implements OnInit, FlowPage {
  @Input() onComplete: (boolean) => void;
  @Input() onDismiss?: () => void;
  @Input() companyUid: string;
  @ViewChild(YshFormComponent) form!: YshFormComponent;
  
  formFields: FormField[] = [];
  formSubmitted: boolean = false;

  constructor(
    private affiliationService: AffiliationService,
    private analytics: AnalyticsService,
    private location: Location,
    private modalService: ModalService,
  ) {}

  ngOnInit(): void {
    this.analytics.trackView('InvitePage');
    this.createFormFieldGroup(1);
  }

  createFormFieldGroup(count: number) {
    while (count--) {
      const index = this.formFields.length / 2 + 1;
      this.formFields.push(
        { 
          group: `group_${index}`,
          name: `email_${index}`,
          label: 'Email',
          type: 'text',
          icon: 'alternate_email',
          half: true, 
          validators: [Validators.required, emailValidator(), duplicateValueValidator(this.formFields, 'email')] 
        },
        { 
          group: `group_${index}`,
          name: `role_${index}`, 
          label: 'Select Role', 
          type: 'text',
          icon: 'expand_more',
          half: true,
          onTap: this.handleSelectRole.bind(this,`role_${index}`), 
          validators: [Validators.required] 
        },
      );
      
    }
  }
  
  // Actions

  async handleSubmitInvite(formValues: any): Promise<void> {  
    const formFieldGroups = [...new Set(this.formFields.map(field => field.group))];
    const affiliationParams: CreateAffiliationParams[] = formFieldGroups.map((_, i) => ({
      email: formValues[`email_${i+1}`],
      role: formValues[`role_${i+1}`].toString().toLowerCase(),
    }));
    try {
      await firstValueFrom(
        this.affiliationService.createBulk(this.companyUid, affiliationParams)
      );
      this.formSubmitted = true;
    } catch (error) {
      console.error(error);
      throw error; // Throw error for YshFormComponent to handle
    }
  }

  handleAddFieldGroup = () => {
    this.createFormFieldGroup(1);
  }

  async handleSelectRole(fieldName: string) {
    const field = this.form?.formGroup.get(fieldName);
    const modal = await this.modalService.present({
      component: SelectRolePage,
      componentProps: {
        initialSelection: field?.value,
        onComplete: (value) => {
          field?.setValue(value);
          this.modalService.dismiss();
        },
        onDismiss: () => {
          this.modalService.dismiss();
        },
      }
    });
    await modal.onDidDismiss();
    field?.value || field?.setValue(null);
    field?.markAsDirty();
  }  

  handleBack = () => {
    this.location.back();
  }
}
