import { BaseEntity, MemoizeGetters } from "./base";

@MemoizeGetters
export class UserSlim extends BaseEntity {
  firstName?: string;
  lastName?: string;
  email?: string;
  phone?: string;

  get name(): string {
    return `${this.firstName || ''} ${this.lastName || ''}`.trim();
  }
}
