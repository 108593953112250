<header class="header" [ngClass]="{'active-mobile-menu': activeMobileMenu}">
  <div class="header__logo">
    <img src="/assets/logos/yoshi-logo-space.svg">
  </div>
  <div class="header__mobile-menu-button" (click)="didTapMobileMenu()">
    @if (activeMobileMenu) {
      <mat-icon size-large>menu_open</mat-icon> Close
    } @else {
      <mat-icon size-large>menu</mat-icon> Menu
    }
  </div>
  <nav class="header__nav">
    @if (companyService.currentCompany$ | async){
      <div class="header__nav__item" (click)="toggleCompanyMenu($event)">
        <mat-icon size-medium>{{isCompanyMenuOpen ? 'expand_less' : 'expand_more'}}</mat-icon>
        {{ (companyService.currentCompany$ | async)?.name}}
        <div class="menu menu-company" [class.open]="isCompanyMenuOpen">
          @for (company of (userService.currentUser$ | async)?.companies; track company) {
            <div class="menu__item" [class.active]="company.uid === (companyService.currentCompany$ | async)?.uid" (click)="didTapCompany(company)">
              {{company.name}}
              <mat-icon size-small>people_alt</mat-icon>
            </div>
          }
        </div>
      </div>
    }
    <a class="header__nav__item" [routerLink]="appRoutes.MyAccount" [matTooltip]="userName">
      <mat-icon size-medium>account_circle</mat-icon> My Account
    </a>
    <a class="header__nav__item" (click)="didTapLogout()">
      <mat-icon size-medium>logout</mat-icon> Logout
    </a>
    <a class="header__nav__item" [href]="emailSupport">
      <mat-icon size-medium>email</mat-icon> Help
    </a>
  </nav>
</header>
