import { Component, OnDestroy, OnInit } from '@angular/core';
import { RouterModule, RouterOutlet, Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { filter, Subject, takeUntil } from 'rxjs';
import { User } from './models/user';
import { UserService } from './services/api/user/user.service';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from '@angular/common';
import { YshMainAsideComponent } from "./components/ysh-main-aside/ysh-main-aside.component";
import { YshMainHeaderComponent } from "./components/ysh-main-header/ysh-main-header.component";
import { YshToastComponent } from "./components/ysh-toast/ysh-toast.component";
import { ToastService } from './services/ui/toast/toast.service';
import { YshDialogComponent } from './components/ysh-dialog/ysh-dialog.component';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
  standalone: true,
  imports: [
    RouterModule,
    RouterOutlet,
    MatIconModule,
    CommonModule,
    YshMainAsideComponent,
    YshMainHeaderComponent,
    YshToastComponent,
    YshDialogComponent,
  ],
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'fleet-portal';
  activeMobileMenu = false;
  hasFramelessLayout = false;
  isLoggedIn = false;
  currentUser: User;
  private unsubscribe: Subject<void> = new Subject();

  constructor(private userService: UserService, private toastService: ToastService, public router: Router, private activatedRoute: ActivatedRoute) {
    this.userService.loggedIn$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(isLoggedIn => (this.isLoggedIn = isLoggedIn));
    this.userService.currentUser$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(user => (this.currentUser = user));

    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(() =>
        this.hasFramelessLayout = !!this.activatedRoute.firstChild?.snapshot.data?.hasFramelessLayout
      );
  }
  
  ngOnInit() {
    this.showLogoutToast();
  }
  
  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  private showLogoutToast() {
    if (localStorage.getItem('showLogoutToast')) {
      this.toastService.show('success','Successful Logout');
      localStorage.removeItem('showLogoutToast');
    }
  }

  didTapLogout() {
    this.userService.logout(true);
    localStorage.setItem('showLogoutToast','true');
  }

  didTapMobileMenu() {
    this.activeMobileMenu = !this.activeMobileMenu;
  }
}
