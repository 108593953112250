<div class="vehicle-page">
  <ysh-page-header
    title={{vehicle?.description}}
    [backLinkRoute]="backLinkRoute"
    backLinkLabel="All Vehicles"
  />
  <ysh-attributes
    [listItems]="vehicleAttributes"
    imageSrc="/assets/images/avatar-default-slate.png"
  />
  <h3>Service History</h3>
  <br>
  <ysh-table-set [dataSource]="dataSource" (onItemTap)="didTapItem($event)"></ysh-table-set>
</div>
