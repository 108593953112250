import { BaseEntity, Default, MemoizeGetters, toDate, Transform } from "./base";
import moment from "moment";

@MemoizeGetters
export class Photo extends BaseEntity {
  url: string;

  @Transform(toDate)
  @Default([])
  takenAt: Date;

  get takenAtFormatted() {
    return moment(this.takenAt).format('llll');
  }
}
