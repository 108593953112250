<ysh-split-layout theme="blue" [hasLogo]="true">
  @if (formFieldsReady) {
    <div>
      <ysh-form
        [formHeading]="formHeading"
        formSubmitLabel="Continue"
        [formLinks]="formLinks"
        [formFields]="formFields"
        [onSubmit]="handleSignup.bind(this)"
      />
      <div class="text">
        @if (form?.formGroup.valid || isInviteMode) {
          <div class="text__legal">
            By continuing you agree to Yoshi’s
            <a href="https://www.yoshimobility.com/terms" target="_blank">Terms of Service</a> and
            <a href="https://www.yoshimobility.com/privacy" target="_blank">Privacy Policy</a>
            You also agree to receive recurring text messages from Yoshi for the purposes of customer
            support, delivery notifications and order reminders. Msg and data rates may apply. Reply HELP
            for help and STOP to unsubscribe.
          </div>
        } @else {
          <div class="text__info">
            <b><mat-icon>people_alt</mat-icon> Need to join an existing organization?</b>
            Please have the org admin invite you from their account.
          </div>
        }
      </div>
    </div>
  }
</ysh-split-layout>
