import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Injectable({
  providedIn: 'root',
})
export class FormatFieldService {

  private static formats = {
    phone: (value: any) => {
      value = value.replace(/\D/g,'');
      const size = value.length;
      if (size > 0) value = '(' + value;
      if (size > 3) value = value.slice(0,4) + ') ' + value.slice(4,11);
      if (size > 6) value = value.slice(0,9) + '-' + value.slice(9);
      return value; // Format: (123) 456-7890
    },
  };

  phoneField(formGroup: FormGroup, formFields: any[]): void {
    formFields
      .filter(({ type }) => type === 'tel')
      .forEach(({ name }) => {
        formGroup.get(name)?.valueChanges.subscribe((value) => {
          formGroup.get(name)?.setValue(FormatFieldService.formats.phone(value), { emitEvent: false });
        });
      });
  }
}
