import arrayToSentence from 'array-to-sentence';
import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";

export const HINT_PASSWORD = 'Min 12 characters with a number, symbol, uppercase letter';

export function checkPasswordValidator(): ValidatorFn {
  return function(control: AbstractControl): ValidationErrors | null {

    let value = control.value;
    let messages = [];
    const length = /.{12,}/;
    const oneLowerCase = /(?=.*[a-z])/;
    const oneUppercase = /(?=.*[A-Z])/;
    const oneNumber = /(?=.*[0-9])/;
    const oneNonAlphanumeric = /(?=.*\W)/;

    if (!length.test(value)) {
      messages.push('12 characters');
    }

    if (!oneUppercase.test(value)) {
      messages.push('one uppercase letter');
    }

    if (!oneLowerCase.test(value)) {
      messages.push('one lowercase letter');
    }

    if (!oneNumber.test(value)) {
      messages.push('one number');
    }

    if (!oneNonAlphanumeric.test(value)) {
      messages.push('one special character');
    }

    if (messages.length) {
      const message = `Password should have at least ${arrayToSentence(messages)}`;
      return { 'password': message }
    }
    return null;
  }
}
