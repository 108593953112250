import { Component, Input, OnInit } from '@angular/core';
import { ComponentWithInputs, TableDataSouce, YshTableSetComponent } from '../../components/ysh-table-set/ysh-table-set.component';
import { MatListModule } from '@angular/material/list';
import { YshPageHeaderComponent } from '../../components/ysh-page-header/ysh-page-header.component';
import { YshAttributesComponent, AttributesListItem } from "../../components/ysh-attributes/ysh-attributes.component";
import { Delivery } from '../../models/delivery';
import { DeliveryService } from '../../services/api/delivery/delivery.service';
import { Order } from '../../models/order';
import { first, of } from 'rxjs';
import { YshBadgeComponent } from '../../components/ysh-badge/ysh-badge.component';
import { YshBadgeActionComponent } from '../../components/ysh-badge-action/ysh-badge-action.component';
import { OrderService } from '../../services/api/order/order.services';
import { DialogService } from '../../services/ui/dialog/dialog.service';
import { CompanyService } from '../../services/api/company/company.service';
import { AppRoutes, companyRoute } from '../../app.routes';

@Component({
  selector: 'app-appointment',
  templateUrl: './appointment.component.html',
  styleUrl: './appointment.component.scss',
  standalone: true,
  imports: [
    MatListModule,
    YshTableSetComponent,
    YshPageHeaderComponent,
    YshAttributesComponent
  ],
})
export class AppointmentComponent implements OnInit {

  @Input() uid: string;

  orderDataSource: TableDataSouce<Order>;
  delivery: Delivery;
  deliveryAttributes: AttributesListItem[];
  backLinkRoute: string;

  constructor(
    private companyService: CompanyService,
    private deliveryService: DeliveryService,
    private dialogService: DialogService, 
    private orderService: OrderService) {}

  ngOnInit(): void {
    this.getDelivery();
    this.getCompany();
  }

  getCompany() {
    this.companyService.currentCompany$.pipe(first()).subscribe(company => {
      this.backLinkRoute = '/' + companyRoute(AppRoutes.Appointments, company);
    });
  }

  getDelivery() {
    this.deliveryService.get(this.uid).subscribe(delivery => {
      this.delivery = delivery;
      this.deliveryAttributes = [
        { label: 'Date', value: this.delivery?.dateString },
        { label: 'Time', value: this.delivery?.timeWindowString},
        { label: 'Vehicle Count', value: this.delivery.vehicleCount},
        { label: 'Total Price', value: this.delivery.costString },
        { label: 'Status', value: this.delivery.status, valueIsBadge: true },
      ];
      this.setDatasource();
    });
  }

  setDatasource() {
    this.orderDataSource = {
      title: "",
      columns: [
        { field: "dateString", title: 'Date', cellSize: 'small'},
        { field: "serviceString", title: 'service', cellSize: 'large'},
        { field: "priceString", title: 'price', cellSize: 'small' },
        { field: "status", title: 'status', cellSize: 'small', component: YshBadgeComponent },
        {
          field: "uid", title: 'Action', cellSize: 'small',
          componentWithInputs: (item) => new ComponentWithInputs(YshBadgeActionComponent, {
            theme: 'coral',
            value: 'Cancel',
            disabled: item.status === 'complete',
            onTap: () => this.didTapCancel(item)
          })
        }
      ],
      actions: [],
      filters: [],
      load: (state) => of({
        items: this.delivery.orders
      })
    }
  }
  

  didTapCancel(order: Order) {
    this.dialogService.showDialog({
      heading: 'Confirm Cancel!',
      caption: 'Are you sure you want to cancel this service?',
      buttonLabelConfirm: 'Yes, Confirm',
      buttonThemeConfirm: 'coral',
      buttonLabelCancel: 'Not now',
      onConfirm: () => {
        this.orderService.cancel(order.uid).subscribe(response => {
          this.getDelivery();
        })
      }
    });
  }
}
