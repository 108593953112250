import { Routes, mapToCanActivate } from '@angular/router';
import { AuthGuard } from './services/guards/auth.guard';
import { SignupComponent } from './pages/signup/signup.component';
import { LoginComponent } from './pages/login/login.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { InvoicesComponent } from './pages/invoices/invoices.component';
import { VehiclesComponent } from './pages/vehicles/vehicles.component';
import { ReportComponent } from './pages/reports/reports.component';
import { VehicleComponent } from './pages/vehicle/vehicle.component';
import { OrderComponent } from './pages/order/order.component';
import { ErrorComponent } from './pages/error/error.component';
import { InspectionComponent } from './pages/inspection/inspection.component';
import { InspectionsComponent } from './pages/inspections/inspections.component';
import { OrderingFlow } from './flows/ordering/ordering.flow';
import { AppointmentsComponent } from './pages/appointments/appointments.component';
import { AppointmentComponent } from './pages/appointment/appointment.component';
import { PasswordResetPage } from './pages/password-reset/password-reset.component';
import { InvitePage } from './flows/invite/invite.page';
import { WelcomeComponent } from './pages/welcome/welcome.component';
import { CompanyComponent } from './pages/companies/company/company.component';
import { CompanyMemberComponent } from './pages/companies/company-member/company-member.component';
import { MyAccountComponent } from './pages/my-account/my-account.component';
import { Company } from './models/company';
import { OrderFlowGuard } from './services/guards/orderflow.guard';

export enum AppRoutes {
  Appointment = 'appointments/:uid',
  Appointments = 'appointments',
  Companies = 'companies',
  Company = 'companies/:companyUid',
  CompanyAppointments = 'companies/:companyUid/appointments',
  CompanyDashboard = 'companies/:companyUid/dashboard',
  CompanyInspections = 'companies/:companyUid/inspections',
  CompanyInvite = 'companies/:companyUid/invite',
  CompanyInvoices = 'companies/:companyUid/invoices',
  CompanyOrderFlow = 'companies/:companyUid/order',
  CompanyMember = 'companies/:companyUid/members/:memberUid',
  CompanyVehicles = 'companies/:companyUid/vehicles',
  Dashboard = 'dashboard',
  Error = 'error',
  Invoices = 'invoices',
  Inspections = 'inspections',
  Inspection = 'inspections/:uid',
  Invite = 'invite',
  Login = 'login',
  MyAccount = 'my-account',
  Orders = 'orders',
  Order = 'orders/:uid',
  OrderFlow = 'order',
  Password = 'password',
  Report = 'reports/:name',
  Root = '',
  Signup = 'signup',
  Vehicles = 'vehicles',
  Vehicle = 'vehicles/:uid',
  Welcome = 'welcome',
}

export function companyRoute(route: AppRoutes, company?: Company) {
  return company
    ? `${AppRoutes.Companies}/${company.uid}/${route}`
    : route
}

export interface RouteData {
  /** Access only if logged out */
  hasLoggedOutAccessOnly?: boolean;
  /** Access only by router.navigate state: {canNavigate: true} */
  hasNavigationAccessOnly?: boolean;
  /** Hide header and aside navigation */
  hasFramelessLayout?: boolean;
}

export const routes: Routes = [
  { path: AppRoutes.Signup,
    component: SignupComponent,
    canActivate: mapToCanActivate([AuthGuard]),
    data: {
      hasLoggedOutAccessOnly: true,
      hasFramelessLayout: true,
    } satisfies RouteData
  },
  { path: AppRoutes.Login,
    component: LoginComponent,
    canActivate: mapToCanActivate([AuthGuard]),
    data: { 
      hasLoggedOutAccessOnly: true,
      hasFramelessLayout: true,
    } satisfies RouteData
  },
  { path: AppRoutes.Dashboard,
    component: DashboardComponent,
    canActivate: mapToCanActivate([AuthGuard]) 
  },
  { path: AppRoutes.Welcome,
    component: WelcomeComponent,
    canActivate: mapToCanActivate([AuthGuard]),
    data: {
      hasNavigationAccessOnly: true,
      hasFramelessLayout: true,
    } satisfies RouteData
  },
  { path: AppRoutes.Company,
    component: CompanyComponent,
    canActivate: mapToCanActivate([AuthGuard]) 
  },
  { path: AppRoutes.CompanyAppointments,
    component: AppointmentsComponent,
    canActivate: mapToCanActivate([AuthGuard]) 
  },
  { path: AppRoutes.CompanyDashboard,
    component: DashboardComponent,
    canActivate: mapToCanActivate([AuthGuard]) 
  },
  { path: AppRoutes.CompanyInspections,
    component: InspectionsComponent,
    canActivate: mapToCanActivate([AuthGuard]) 
  },
  { path: AppRoutes.CompanyInvite,
    component: InvitePage,
    canActivate: mapToCanActivate([AuthGuard]), 
    data: {
      hasFramelessLayout: true,
    } satisfies RouteData
  },
  { path: AppRoutes.CompanyInvoices,
    component: InvoicesComponent,
    canActivate: mapToCanActivate([AuthGuard]) 
  },
  { path: AppRoutes.CompanyMember,
    component: CompanyMemberComponent,
    canActivate: mapToCanActivate([AuthGuard]) 
  },
  { path: AppRoutes.CompanyOrderFlow,
    component: OrderingFlow,
    canActivate: mapToCanActivate([AuthGuard, OrderFlowGuard]),
    data: {
      hasFramelessLayout: true,
    } satisfies RouteData
  },
  { path: AppRoutes.CompanyVehicles,
    component: VehiclesComponent,
    canActivate: mapToCanActivate([AuthGuard]) 
  },
  { path: AppRoutes.MyAccount,
    component: MyAccountComponent,
    canActivate: mapToCanActivate([AuthGuard]) 
  },
  { path: AppRoutes.Invoices,
    component: InvoicesComponent,
    canActivate: mapToCanActivate([AuthGuard]) 
  },
  { path: AppRoutes.Inspections,
    component: InspectionsComponent,
    canActivate: mapToCanActivate([AuthGuard]) 
  },
  { path: AppRoutes.Inspection,
    component: InspectionComponent,
    canActivate: mapToCanActivate([AuthGuard]) 
  },
  { path: AppRoutes.Invite,
    component: InvitePage,
    canActivate: mapToCanActivate([AuthGuard]),
    data: {
      hasFramelessLayout: true,
    } satisfies RouteData
  },
  { path: AppRoutes.Vehicles,
    component: VehiclesComponent,
    canActivate: mapToCanActivate([AuthGuard]) 
  },
  { path: AppRoutes.Vehicle,
    component: VehicleComponent,
    canActivate: mapToCanActivate([AuthGuard]),
  },
  { path: AppRoutes.Appointment,
    component: AppointmentComponent,
    canActivate: mapToCanActivate([AuthGuard]) 
  },
  { path: AppRoutes.Appointments,
    component: AppointmentsComponent,
    canActivate: mapToCanActivate([AuthGuard]) 
  },
  { path: AppRoutes.Report,
    component: ReportComponent,
    canActivate: mapToCanActivate([AuthGuard]) 
  },
  { path: AppRoutes.Order,
    component: OrderComponent,
    canActivate: mapToCanActivate([AuthGuard]) 
  },
  { path: AppRoutes.OrderFlow,
    component: OrderingFlow,
    canActivate: mapToCanActivate([AuthGuard]),
    data: {
      hasFramelessLayout: true,
    } satisfies RouteData
  },
  {
    path: AppRoutes.Password,
    component: PasswordResetPage,
    canActivate: mapToCanActivate([AuthGuard]),
    data: {
      hasLoggedOutAccessOnly: true,
      hasFramelessLayout: true,
    } satisfies RouteData
  },
  { path: AppRoutes.Root,
    component: DashboardComponent,
    canActivate: mapToCanActivate([AuthGuard]) 
  },
  { path: `${AppRoutes.Error}/:errorType`,
    component: ErrorComponent
  },
  // KEEP LAST (Redirect all unknown paths to error page 404)
  { path: '**',
    redirectTo: `${AppRoutes.Error}/404`
  }
];
