import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';

@Component({
  selector: 'ysh-main-header',
  templateUrl: './ysh-main-header.component.html',
  styleUrl: './ysh-main-header.component.scss',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    MatIconModule,
    MatTooltipModule
  ],
})
export class YshMainHeaderComponent {
  @Input() userName: string;
  @Input() activeMobileMenu: boolean;
  @Output() onTapLogout = new EventEmitter();
  @Output() onTapMobileMenu = new EventEmitter();

  didTapLogout() {
    this.onTapLogout.emit();
  }

  didTapMobileMenu() {
    this.onTapMobileMenu.emit();
  }
}
