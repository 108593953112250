<div class="company-member-page">
  <ysh-page-header title="Member Account" [backLinkRoute]="backLinkRoute" backLinkLabel="Back to Company"/>
  <div class="panels">
    <ysh-panel
      class="panel"
      heading="Information"
      padding="left-right-bottom">

      <ysh-traits [traits]="memberInfo" imageSrc="assets/images/avatar-default-slate.png"/>
    </ysh-panel>
  </div>
</div>
