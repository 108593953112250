import { BaseEntity, toEntity, Transform } from "./base";
import { ServiceType } from "./service_type";

export class Service extends BaseEntity{
  name: string;
  price: number;
  description: string;
  enabled: boolean;

  @Transform(toEntity(ServiceType))
  serviceType: ServiceType
}
