import { BaseEntity, MemoizeGetters, toEntity, Transform } from "./base";
import { Company } from "./company";
import { UserSlim } from "./user-slim";

export enum Role {
  Admin = 'admin',
  Driver = 'driver',
  Owner = 'owner'
}

@MemoizeGetters
export class Affilation extends BaseEntity {
  @Transform(toEntity(Company))
  company?: Company;

  @Transform(toEntity(UserSlim))
  user?: UserSlim;

  role: Role;

  get userName() {
    return this.user.name;
  }

  get userEmail() { 
    return this.user.email;
  }

  get userPhone() {
    return this.user.phone;
  }

  get userPhoneFormatted(): string {
    // (123) 456-7890
    return this.user.phone?.replace(/\D/g, '').replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
  }

  get roleFormatted(): string {
    return this.role?.[0].toUpperCase() + this.role?.slice(1);
  }
}

export interface CreateAffiliationParams {
  email: string
}