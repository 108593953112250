import { Component, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { YshBadgeTheme, YshBadgeComponent } from '../ysh-badge/ysh-badge.component';

export interface Trait {
  label: string,
  value: any,
  linkHref?: string,
  linkIcon?: string;
  /** Place icon after text */
  linkIconReverse?: boolean;
  linkStyle?: 'underline' | 'colored';
  badgeTheme?: YshBadgeTheme;
}

@Component({
  selector: 'ysh-traits',
  templateUrl: './ysh-traits.component.html',
  styleUrl: './ysh-traits.component.scss',
  standalone: true,
  imports: [YshBadgeComponent, MatIconModule],
})
export class YshTraitsComponent {
  @Input() traits: Trait[] = [];
  @Input() heading?: string;
  @Input() imageSrc?: string;
  @Input() labelOpacity?: 'full' | 'half' = 'half';

  getLinkStyle(trait: Trait): string {
    return trait.linkStyle || 'underline';
  }
  
  getLinkTarget(trait: Trait): string {
    return trait.linkHref && trait.linkHref.startsWith('http') ? '_blank' : '_self';
  }
}
