<main class="main">
  @if (isLoggedIn) {
    <ysh-main-header
      [userName]="currentUser?.name" 
      [activeMobileMenu]="activeMobileMenu"
      (onTapMobileMenu)="didTapMobileMenu()"
      (onTapLogout)="didTapLogout()"
    />
    <ysh-main-aside
      [activeMobileMenu]="activeMobileMenu"
      (click)="didTapMobileMenu()"
    />
  }
  <section class="content">
    <router-outlet />
  </section>
</main>
