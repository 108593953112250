import { Component, Input, OnInit } from '@angular/core';
import { NavigationEnd, Router, RouterLinkActive, RouterModule } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { Report } from './../../models/report';
import { CommonModule } from '@angular/common';
import { ReportService } from '../../services/report.service';
import { AppRoutes, companyRoute } from '../../app.routes';
import { CompanyService } from '../../services/api/company/company.service';
import { Company } from '../../models/company';
import { combineLatest, filter, map, mergeMap } from 'rxjs';
import { Action, PermissionService, Resource, ResourcePermissions } from '../../services/permission/permission.service';
import { UserService } from '../../services/api/user/user.service';
import { User } from '../../models/user';

export interface NavItem {
  label: string,
  icon?: string,
  route?: AppRoutes | string,
  isSubnavOpen?: boolean;
  subnav?: {
    label: string,
    iconPlus?: boolean;
    route: AppRoutes | string
  }[],
}

@Component({
  selector: 'ysh-main-aside',
  templateUrl: './ysh-main-aside.component.html',
  styleUrl: './ysh-main-aside.component.scss',
  standalone: true,
  imports: [CommonModule, RouterModule, RouterLinkActive, MatIconModule],
})
export class YshMainAsideComponent implements OnInit {
  @Input() activeMobileMenu: boolean;

  reports: Report[] = [];
  navItems: NavItem[] = [];

  constructor(
    private companyService: CompanyService, 
    private permissions: PermissionService,
    private reportService: ReportService, 
    private userService: UserService,
    private router: Router
  ){}

  ngOnInit(): void {
    this.reportService.getMany().subscribe(({items}) => {
      this.reports = items;
      this.addReportsToNav(items);
    });

    combineLatest([this.userService.currentUser$, this.companyService.currentCompany$ ]).pipe(
      filter(([user]) => !!user),
      mergeMap(([user, company])=>{
        return this.permissions.getPermissions(company).pipe(
          map(permissions=>[company, permissions] as const)
        )
      })
    ).subscribe(response => {
        this.navItems = this.getNavItems(...response);
        this.setSubnavState();
    });

    this.userService.getUser();
  }

  addReportsToNav(reports: Report[]) {
    if (reports?.length) {
      const reportsNav: NavItem = {
        label: 'Reports',
        icon: 'description',
        subnav: []
      }

      reports.forEach(report => {
        const route = `reports/${report.name}`
        reportsNav.subnav.push({
          label: report.title,
          route
        })
      })
      this.navItems = [...this.navItems, reportsNav];
    }
  }
  
  setSubnavState(event?: MouseEvent, tappedItem?: NavItem) {
    if (event && tappedItem) {
      event.stopPropagation();
      tappedItem.isSubnavOpen = !tappedItem.isSubnavOpen;
    } else {
      this.navItems.forEach(item => {
        item.isSubnavOpen = item.subnav?.some(sub => this.router.isActive(sub.route, true));
      });
    }
  }

  getNavItems(company: Company | null, permissions: ResourcePermissions): NavItem[] {
    const navItems:NavItem[] = [];
    if (!company || permissions.canAccess(Action.GetMany, Resource.Dashboard)){
      navItems.push(
        {
          route: companyRoute(AppRoutes.Dashboard, company),
          label: 'Dashboard',
          icon: 'dashboard',
        }
      );
    }

    const inspectionItems: any[] = []
    if (!company || permissions.canAccess(Action.GetMany, Resource.Delivery)){
      inspectionItems.push({
        route: companyRoute(AppRoutes.Appointments, company),
        label: 'Appointments',
      })
    }

    if (!company || permissions.canAccess(Action.GetMany, Resource.Delivery)){
      inspectionItems.push({
        route: companyRoute(AppRoutes.Inspections, company),
        label: 'Inspection History',
      })
    }

    if (!company || permissions.canAccess(Action.Post, Resource.Delivery)){
      inspectionItems.push({
        route: companyRoute(AppRoutes.OrderFlow, company),
        label: 'Schedule Inspection',
        iconPlus: true,
      })
    }

    if ( inspectionItems.length) {
      navItems.push({
        label: 'Inspections',
        icon: 'task',
        subnav: inspectionItems  
      })
    }

    if (!company || permissions.canAccess(Action.GetMany, Resource.Vehicle)){
      navItems.push(
        {
          route: companyRoute(AppRoutes.Vehicles, company),
          label: 'Vehicles',
          icon: 'local_shipping',
        }
      );
    }

    if (!company || permissions.canAccess(Action.GetMany, Resource.Invoice)){
      navItems.push(
        {
          route: companyRoute(AppRoutes.Invoices, company),
          label: 'Invoices',
          icon: 'request_quote',
        }
      );
    }

    if (company && permissions.canAccess(Action.GetMany, Resource.Affiliation)) {
      navItems.push(
        {
          route: `${AppRoutes.Companies}/${company.uid}`,
          label: 'Company',
          icon: 'people_alt',
        }
      );
    }
    return navItems
  }

}
