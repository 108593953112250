import { Component, OnInit, Type } from '@angular/core';
import { MatGridListModule } from '@angular/material/grid-list';
import { Subject, takeUntil } from 'rxjs';
import { User } from '../../models/user';
import { UserService } from '../../services/user/user.service';
import { YshGridComponent, GridTile } from "../../components/ysh-grid/ysh-grid.component";
import { YshChartComponent } from '../../components/ysh-chart/ysh-chart.component';
import { YshPageHeaderComponent } from "../../components/ysh-page-header/ysh-page-header.component";
import { YshGridTileContentComponent } from '../../components/ysh-grid-tile-content/ysh-grid-tile-content.component';
import { Dashboard, TyleType } from '../../models/dashboard';
import { DashboardService } from '../../services/dashboard/dashboard.service';


const TileComponentMap: Record<TyleType, Type<any>> = {
  chart: YshChartComponent,
  single_value: YshGridTileContentComponent
}

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrl: './dashboard.component.scss',
  standalone: true,
  imports: [MatGridListModule, YshGridComponent, YshPageHeaderComponent],
})
export class DashboardComponent implements OnInit{

  static readonly DASHBOARD_NAME = 'AccountDashboard';

  dashboard: Dashboard;
  gridTiles: GridTile[];
  isLoggedIn = false;
  currentUser: User;

  private unsubscribe: Subject<void> = new Subject();

  constructor(private userService: UserService, private dashboardService: DashboardService) {
    this.userService.loggedIn$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(isLoggedIn => (this.isLoggedIn = isLoggedIn));
    this.userService.currentUser$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(user => (this.currentUser = user));
  }

  ngOnInit(): void {
    this.dashboardService.get(DashboardComponent.DASHBOARD_NAME).subscribe(dashboard => {
      this.dashboard = dashboard;
      this.createGridTiles();
    })
  }

  createGridTiles() {
    this.gridTiles = this.dashboard.tiles.map(tile => {
      return {
        heading: tile.title,
        subheading: tile.subtitle,
        infotip: tile.infotip,
        gridArea: `span ${tile.layout?.spanY || 'auto'} / span ${tile.layout?.spanX || 'auto'}`,
        component: TileComponentMap[tile.type],
        componentInput: {
          ...(tile.chartType && {type: tile.chartType}),
          ...(tile.data && {data: tile.data}),
          ...(tile.dataFormat && {dataFormat: tile.dataFormat}),
          ...(tile.value && {text: tile.value}),
        }
      }
    });
  }
}
