
import { Injectable } from '@angular/core';
import { ApiService } from '../../api.service';
import { Dashboard } from '../../../models/dashboard';
import { AuthService } from '../../auth/auth.service';

@Injectable({
  providedIn: 'root',
})
export class DashboardService {
  constructor(private apiService: ApiService) {}

  get(name: string, accountUid?: string) {
    const url = `/users/${accountUid || AuthService.USER_UID}/dashboards/${name}`;
    return this.apiService.get(url, Dashboard);
  }
}
