import { Component, Input } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'ysh-page-header',
  templateUrl: './ysh-page-header.component.html',
  styleUrl: './ysh-page-header.component.scss',
  standalone: true,
  imports: [RouterModule, MatIconModule],
})
export class YshPageHeaderComponent {
  @Input() title: string;
  @Input() subtitle?: string;
  @Input() backLinkRoute?: string;
  @Input() backLinkLabel?: string = 'Back';
}
