import { Component, Input, OnInit, Type } from '@angular/core';
import { MatGridListModule } from '@angular/material/grid-list';
import { first, map, mergeMap, Subject, take, takeUntil } from 'rxjs';
import { User } from '../../models/user';
import { UserService } from '../../services/api/user/user.service';
import { YshGridComponent, GridTile } from "../../components/ysh-grid/ysh-grid.component";
import { YshChartComponent } from '../../components/ysh-chart/ysh-chart.component';
import { YshPageHeaderComponent } from "../../components/ysh-page-header/ysh-page-header.component";
import { YshGridTileContentComponent } from '../../components/ysh-grid-tile-content/ysh-grid-tile-content.component';
import { Dashboard, TyleType } from '../../models/dashboard';
import { DashboardService } from '../../services/api/dashboard/dashboard.service';
import { CompanyService } from '../../services/api/company/company.service';
import { Company } from '../../models/company';
import { Router } from '@angular/router';
import { AppRoutes, companyRoute } from '../../app.routes';

const TileComponentMap: Record<TyleType, Type<any>> = {
  chart: YshChartComponent,
  single_value: YshGridTileContentComponent
}

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrl: './dashboard.component.scss',
  standalone: true,
  imports: [MatGridListModule, YshGridComponent, YshPageHeaderComponent],
})
export class DashboardComponent implements OnInit{

  static readonly DASHBOARD_NAME = 'InspectionDashboard';

  @Input() companyUid?: string;

  dashboard: Dashboard;
  gridTiles: GridTile[];
  isLoggedIn = false;
  currentUser: User;
  currentCompany: Company;

  private unsubscribe: Subject<void> = new Subject();

  constructor(private router: Router, private userService: UserService, private dashboardService: DashboardService, private companyService: CompanyService) {
    this.userService.loggedIn$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(isLoggedIn => (this.isLoggedIn = isLoggedIn));
    this.userService.currentUser$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(user => (this.currentUser = user));
  }

  ngOnInit(): void {
    const request = this.companyUid 
      ? this.companyService.get(this.companyUid).pipe(map((company) => company?.accountUid))
      : this.userService.currentUser$.pipe(first(), map((user) => user?.uid))
    request.pipe(
      mergeMap(accountUid => {
        return this.dashboardService.get(DashboardComponent.DASHBOARD_NAME, accountUid)
      })
    ).subscribe(dashboard => {
      this.dashboard = dashboard;
      this.createGridTiles();
    });
    this.getCompany();
  }

  getCompany() {
    this.companyService.currentCompany$.subscribe(company => {
      this.currentCompany = company;
      // Force company-scope route if company exists
      this.router.navigateByUrl(
        companyRoute(AppRoutes.Dashboard, company),
        { replaceUrl: true }
      );
    });
  }

  createGridTiles() {
    this.gridTiles = this.dashboard.tiles.map(tile => {
      return {
        heading: tile.title,
        subheading: tile.subtitle,
        infotip: tile.infotip,
        gridArea: `span ${tile.layout?.spanY || 'auto'} / span ${tile.layout?.spanX || 'auto'}`,
        component: TileComponentMap[tile.type],
        componentInput: {
          ...(tile.chartType && {type: tile.chartType}),
          ...(tile.data && {data: tile.data}),
          ...(tile.dataFormat && {dataFormat: tile.dataFormat}),
          ...(tile.value !== null && {text: tile.value}),
        }
      }
    });
  }
}
