import { ApiService, GetManyParams } from '../../api.service';
import { Injectable } from '@angular/core';
import { AuthService } from '../../auth/auth.service';
import { Service } from '../../../models/service';

export interface ServiceParams extends GetManyParams {
  companyUid?: string;
}

@Injectable({
  providedIn: 'root',
})
export class ServiceService {
  constructor(private apiService: ApiService) {}

  getMany(params: ServiceParams) {
    const url = `/users/${AuthService.USER_UID}/services`;
    return this.apiService.getMany(url, { ...params, includeFromGlobalRegion: true  }, Service);
  }
}
