import { Component, Input, OnInit } from '@angular/core';
import { User } from '../../../models/user';
import { YshPageHeaderComponent } from "../../../components/ysh-page-header/ysh-page-header.component";
import { UserService } from '../../../services/api/user/user.service';
import { YshPanelComponent } from '../../../components/ysh-panel/ysh-panel.component';
import { Trait, YshTraitsComponent } from '../../../components/ysh-traits/ysh-traits.component';
import { CreditCardService } from '../../../services/api/credit-card/credit-card.service';
import { MatIconModule } from '@angular/material/icon';
import { ModalService } from '../../../services/ui/modal/modal.service';
import { AddCardPage } from '../../../flows/add-card/add-card.page';
import { TableDataSouce, YshTableSetComponent } from '../../../components/ysh-table-set/ysh-table-set.component';
import { YshButtonComponent } from "../../../components/ysh-button/ysh-button.component";
import { first, of } from 'rxjs';
import { AppRoutes, companyRoute } from '../../../app.routes';
import { ActivatedRoute, Router } from '@angular/router';
import { CompanyService } from '../../../services/api/company/company.service';
import { Company } from '../../../models/company';
import { Affilation } from '../../../models/affiliation';
import { AffiliationService } from '../../../services/api/affiliation/affiliation.service';
import { Action, PermissionService, Resource, ResourcePermissions } from '../../../services/permission/permission.service';

@Component({
  selector: 'app-company',
  templateUrl: './company.component.html',
  styleUrl: './company.component.scss',
  standalone: true,
  imports: [YshPageHeaderComponent, YshPanelComponent, YshTraitsComponent, MatIconModule, YshTableSetComponent, YshButtonComponent],
})
export class CompanyComponent implements OnInit {

  @Input() companyUid: string;

  company: Company;
  companyInfo?: Trait[];
  companyCreditCardInfo?: Trait[];
  dataSourceMembers: TableDataSouce<Affilation>;
  
  allowCreateCreditCard = false;
  allowViewCreditCard = false;
  allowInviteTeamMembers= false;
  allowViewTeamMembers = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private affiliationService: AffiliationService,
    private creditCardService: CreditCardService,
    private companyService: CompanyService,
    private modalService: ModalService,
    private permissionService: PermissionService,
    private router: Router,
  ) {}
  
  ngOnInit() {
    this.getCompany();


  }

  getCompany() {
    this.companyService.get(this.companyUid).subscribe(company => {
      if (company) {
        this.company = company;
        this.affiliationService.getMany({}, company.uid).subscribe(members => {
          const owner = members.items.find(member => member.role === 'owner');
          this.companyInfo = [
            { label: 'Company', value: company?.name },
            { label: 'Account Owner', value: owner?.userName },
            { label: 'Account Created', value: company?.createdAtFormatted },
          ];
        });
        this.getMembersList(company);
        this.getUserCreditCard(company);
        this.getCompanyPermissions(company);
      }
    });
  }

  getCompanyPermissions(company: Company) {
    const resources = [Resource.Affiliation, Resource.CreditCard];
    this.permissionService.getPermissions(company, resources).subscribe(permissions => {
      this.allowViewCreditCard = permissions.canAccess(Action.GetMany, Resource.CreditCard);
      this.allowCreateCreditCard = permissions.canAccess(Action.Post, Resource.CreditCard);
      this.allowViewTeamMembers = permissions.canAccess(Action.GetMany, Resource.Affiliation);
      this.allowInviteTeamMembers = permissions.canAccess(Action.Post, Resource.Affiliation);
    })
  }

  getUserCreditCard(company: Company) {
    this.creditCardService.getMany({}, company.accountUid).subscribe(cards => {
      const card = cards.items[0];
      this.companyCreditCardInfo = card && [
        { label: 'Card Number', value: '•••• •••• •••• ' + card?.last4 },
        { label: 'Card Type', value: card?.brand },
      ]
    })
  }  
  
  getMembersList(company: Company) {
    this.dataSourceMembers = {
      title: '',
      columns: [
        { title: 'Name', field: 'userName' },
        { title: 'Email', field: 'userEmail', cellSize: 'large' },
        { title: 'Phone', field: 'userPhoneFormatted', cellSize: 'small' },
        { title: 'Role', field: 'roleFormatted', cellSize: 'small'  }
      ],
      load: (state) => this.affiliationService.getMany(state, company.uid),
      displayRowNav: true,
      filters: [],
      actions: [],
    };
  }

  // Actions

  handleAddCreditCard() {
    this.modalService.present(
      {
        component: AddCardPage,
        componentProps: {
          company: this.company,
          onComplete: () => {
            this.getUserCreditCard(this.company);
          } 
        } 
      }
    );
  }

  handleInviteMembers() {
    this.router.navigateByUrl(companyRoute(AppRoutes.Invite, this.company));
  }

  handleNavigateToMember(member: any) {
    this.router.navigate(['members', member.uid], { 
      relativeTo: this.activatedRoute 
    });
  }
}
