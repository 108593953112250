import { AfterContentInit, Component, EventEmitter, Input, Output } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { YshDotsComponent } from "../ysh-dots/ysh-dots.component";

@Component({
  selector: 'ysh-panel',
  templateUrl: './ysh-panel.component.html',
  styleUrl: './ysh-panel.component.scss',
  standalone: true,
  imports: [MatIconModule, MatTooltipModule, YshDotsComponent]
})
export class YshPanelComponent implements AfterContentInit {
  /** For content only. Excludes headbar if visible. */
  @Input() padding?: 'all-sides' | 'top-bottom' | 'left-right' | 'left-right-top' | 'left-right-bottom';
  @Input() heading?: string;
  @Input() infotip?: string;
  @Input() actionLabel?: string;
  @Output() onAction = new EventEmitter();

  contentLoaded: boolean = false;

  ngAfterContentInit(): void {
    this.contentLoaded = true;
  }

  handleAction() {
    this.onAction.emit();
  }
}
